// // // // import React, { useState, useEffect } from 'react';
// // // // import Header from '../layout/Header';
// // // // import { useLocation, useNavigate } from 'react-router-dom';
// // // // import Select from 'react-select';
// // // // import config from '../config';

// // // // const CustomOption = ({ innerProps, label }) => (
// // // // <div {...innerProps}>{label}</div>
// // // // );
// // // // export default function EditEWT() {
// // // //   const location = useLocation();
// // // //   const navigate = useNavigate();
// // // //   // Separate state variables to store initial data from URL
// // // //   const [initialLobName, setInitialLobName] = useState(location.state ? location.state.lobName : '');
// // // //   const [initialListOfQueues, setInitialListOfQueues] = useState([]);
// // // //   const [initialQueueNames, setInitialQueueNames] = useState([]);
// // // //   const [initialDropdownComponents, setInitialDropdownComponents] = useState([0]);
// // // //   const [initialPercentages, setInitialPercentages] = useState([]);
// // // //   const [initialSuccessMessage, setInitialSuccessMessage] = useState('');
// // // //   // Your existing state variables for form data
// // // //   const [lobName, setLobName] = useState(initialLobName);
// // // //   const [listOfQueues, setListOfQueues] = useState(initialListOfQueues);
// // // //   const [queueNames, setQueueNames] = useState(initialQueueNames);
// // // //   const [dropdownComponents, setDropdownComponents] = useState(initialDropdownComponents);
// // // //   const [percentages, setPercentages] = useState(initialPercentages);
// // // //   const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);
// // // //   const [lobNameError, setLobNameError] = useState('');
// // // //   const [percentageError, setPercentageError] = useState('');
// // // //   const customDropdownStyles = {
// // // //     control: (provided) => ({
// // // //       ...provided,
// // // //       width: 300, // Adjust the width as needed
// // // //     }),
// // // //   };
// // // //   useEffect(() => {
// // // //     // Fetch queue names on component mount
// // // //     fetchQueueNames();
// // // //   }, []);
// // // //   useEffect(() => {
// // // //     const searchParams = new URLSearchParams(location.search);
// // // //     const lobNameParam = searchParams.get('lobName');
// // // //     if (lobNameParam) {
// // // //       setLobName(lobNameParam);
// // // //     }
// // // //     const queueNamesParam = searchParams.get('queueNames');
// // // //     const percentagesParam = searchParams.get('percentages');
// // // //     const queueidsParam = searchParams.get('queueids');
// // // //     if (queueNamesParam && percentagesParam) {
// // // //       const queueNamesArray = queueNamesParam.split(',');
// // // //       const percentagesArray = percentagesParam.split(',');
// // // //       const queueidsArray = queueidsParam.split(',');
// // // //       const selectedQueues = queueNamesArray.map((queueName, index) => ({
// // // //         label: queueName,
// // // //         value: index,
// // // //       }));
// // // //       setListOfQueues(selectedQueues);
// // // //       setPercentages(percentagesArray);
// // // //     }
// // // //   }, [location.search]);
// // // //   const fetchQueueNames = () => {
// // // //     fetch(`${config.apiUrl}/getqueues`, {
// // // //       method: 'POST',
// // // //       headers: {
// // // //         'Authorization': config.token,
// // // //       },
// // // //     })
// // // //       .then((response) => response.json())
// // // //       .then((data) => {
// // // //         const options = data.map((queue) => ({
// // // //           value: queue.id,
// // // //           label: queue.name,
// // // //         }));
// // // //         setQueueNames(options);
// // // //       })
// // // //       .catch((error) => {
// // // //         console.error('Error fetching queue names:', error);
// // // //       });
// // // //   };
// // // //   const handleInputChange = (event) => {
// // // //     const value = event.target.value;
// // // //     // Validate the input to contain only characters
// // // //     if (/^[a-zA-Z ]*$/.test(value)) {
// // // //       setLobName(value);
// // // //       setLobNameError(''); // Clear validation error
// // // //     } else {
// // // //       setLobNameError('LOB Name should only contain characters.');
// // // //     }
// // // //   };

// // // //   const handleQueueChange = (options, dropdownIndex) => {
// // // //     // Update the selected queue at the specified index
// // // //     const updatedListOfQueues = [...listOfQueues];
// // // //     updatedListOfQueues[dropdownIndex] = options;
// // // //     setListOfQueues(updatedListOfQueues);
// // // //     // Create a copy of the percentages array
// // // //     const updatedPercentages = [...percentages];
// // // //     // Replace the percentage for the same index with a new value
// // // //     // You can set a default percentage or any other value as needed
// // // //     updatedPercentages[dropdownIndex] = 0; // Set to 0 for example
// // // //     setPercentages(updatedPercentages);
// // // //   };
// // // //   const handlePercentageChange = (value, dropdownIndex) => {
// // // //     const updatedPercentages = [...percentages];
// // // //     updatedPercentages[dropdownIndex] = value;
// // // //     setPercentages(updatedPercentages);
// // // //   };
// // // //   const handleAddQueueClick = () => {
// // // //     // Add an empty array for the newly added dropdown
// // // //     setListOfQueues((prevListOfQueues) => [...prevListOfQueues, []]);
// // // //     // Add an empty percentage for the newly added dropdown
// // // //     setPercentages((prevPercentages) => [...prevPercentages, 0]);
// // // //     // Add a new dropdown component
// // // //     setDropdownComponents((prevDropdownComponents) => [...prevDropdownComponents, prevDropdownComponents.length]);
// // // //   };


 
// // // // const handleEditClick = (event) => {
// // // //   event.preventDefault();
// // // //   // Check if lobName is empty or contains only spaces
// // // //   if (lobName.trim() === '') {
// // // //     setLobNameError('LOB Name is required.');
// // // //     return;
// // // //   }
 
// // // //   // Calculate the total percentage
// // // //   const totalPercentage = percentages.reduce((acc, percentage) => acc + Number(percentage), 0);
 
// // // //   // Check if the total percentage is not equal to 100
// // // //   if (totalPercentage !== 100) {
// // // //     setSuccessMessage(''); // Clear any previous success message
// // // //     setPercentageError('Total percentage should be 100');
// // // //     return;
// // // //   } else {
// // // //     setPercentageError(''); // Clear the percentage error if the total is 100
// // // //   }
 
// // // //   // Create an array to store updated queue data
// // // //   const updatedQueues = listOfQueues.map((queues, index) => {
// // // //     if (queues.length > 0 && queues[0].label) {
// // // //       return {
// // // //         queuename: queues[0].label,
// // // //         queueid: queues[0].value,
// // // //         percentage: percentages[index],
// // // //       };
// // // //     } else {
// // // //       // Handle the case where 'label' is not defined (e.g., display an error or ignore)
// // // //       return null;
// // // //     }
// // // //   });
 
// // // //   // Filter out any null entries (optional, if you want to remove them)
 
// // // //   const filteredUpdatedQueues = updatedQueues.filter((queue) => queue !== null);
 
// // // //   // Merge the data from URL parameters (if available)
// // // //   const searchParams = new URLSearchParams(location.search);
// // // //   const queueNamesParam = searchParams.get('queueNames');
// // // //   const percentagesParam = searchParams.get('percentages');
// // // //   const queueidsParam = searchParams.get('queueids');
 
// // // //   if (queueNamesParam && percentagesParam && queueidsParam) {
// // // //     const queueNamesArray = queueNamesParam.split(',');
// // // //     const percentagesArray = percentagesParam.split(',');
// // // //     const queueidsArray = queueidsParam.split(',');
 
// // // //     // Add the data from the URL parameters to the updatedQueues array
// // // //     queueNamesArray.forEach((queueName, index) => {
// // // //       // Check if the queue already exists in the updated data
// // // //       const existingQueueIndex = filteredUpdatedQueues.findIndex(queue => queue.queuename === queueName);
 
// // // //       if (existingQueueIndex !== -1) {
// // // //         // If the queue exists, update its percentage and queueid
// // // //         filteredUpdatedQueues[existingQueueIndex].percentage = percentagesArray[index];
// // // //         filteredUpdatedQueues[existingQueueIndex].queueid = queueidsArray[index];
// // // //       } else {
// // // //         // If the queue doesn't exist, add it as a new entry
// // // //         filteredUpdatedQueues.push({
// // // //           queuename: queueName,
// // // //           queueid: queueidsArray[index], // Use the corresponding queueid from the URL
// // // //           percentage: percentagesArray[index],
// // // //         });
// // // //       }
// // // //     });
// // // //   }
 
// // // //   // Your code to handle the edit action, including the percentages
// // // //   const requestData = {
// // // //     lob: lobName,
// // // //     queues: filteredUpdatedQueues, // Pass the updated queue data
// // // //   };
 
// // // //   // Submit the requestData to your server endpoint
// // // //   fetch(`${config.apiUrl}/editpercentage`, {
// // // //     method: 'POST',
// // // //     headers: {
// // // //       'Authorization': config.token,
// // // //       'Content-Type': 'application/json',
// // // //     },
// // // //     body: JSON.stringify(requestData),
// // // //   })
// // // //     .then((response) => response.json())
// // // //     .then((insertData) => {
// // // //       // Handle the response from the API
// // // //       setSuccessMessage('Data updated successfully');
// // // //       console.log(insertData);
// // // //       setTimeout(() => {
// // // //         // Use your navigation logic here, for example:
// // // //         navigate('/percentage');
// // // //       }, 1000);
// // // //     })
// // // //     .catch((error) => {
// // // //       console.error('Error updating EWT configuration:', error);
// // // //     });
// // // // };
 
// // // //   return (
// // // // <div>
// // // // <Header />
// // // // <div className="flex flex-col items-center">
// // // // <br />
// // // // <div className="flex items-center">
// // // // <label htmlFor="lobName" className="text-center mr-2 font-roboto">
// // // //             LOB Name:
// // // // </label>
// // // // <input
// // // //             type="text"
// // // //             id="lobName"
// // // //             className="border border-black-200 px-1 py-0 w-50 text-center"
// // // //             placeholder="Enter LOB Name"
// // // //             value={lobName}
// // // //             onChange={handleInputChange}
// // // //             style={{ marginLeft: '50px' }}
// // // //           />
// // // // </div>
// // // //         {lobNameError && <p className="text-red-500">{lobNameError}</p>}
// // // // <br />
// // // // <br />
// // // // <div>
// // // //           {dropdownComponents.map((componentIndex) => (
// // // // <div key={componentIndex} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
// // // // <div style={{ marginRight: '10px' }}>
// // // // <h2 className="text-l font-semibold mb-2">Queue Name</h2>
// // // // <Select
// // // //                   options={queueNames}
// // // //                   value={listOfQueues[componentIndex] || []}
// // // //                   isMulti
// // // //                   onChange={(options) => handleQueueChange(options, componentIndex)}
// // // //                   components={{ Option: CustomOption }}
// // // //                   styles={customDropdownStyles}
// // // //                 />
// // // // </div>
// // // // <div>
// // // // <h2 className="text-l font-semibold mb-2">Percentage</h2>
// // // // <input
// // // //                   type="number"
// // // //                   min="0"
// // // //                   max="100"
// // // //                   value={percentages[componentIndex] || 0}
// // // //                   onChange={(e) => handlePercentageChange(e.target.value, componentIndex)}
// // // //                   className="border border-black-200 px-1 py-0 w-50 text-center"
// // // //                   placeholder="Enter Percentage"
// // // //                 />
// // // // </div>
// // // // </div>
// // // //           ))}
// // // // </div>
// // // //         {percentageError && <p className="text-red-500">{percentageError}</p>}
// // // // <br />
// // // // <br />
// // // // <div>
// // // // <button
// // // //             onClick={handleAddQueueClick}
// // // //             className="bg-purple-900 text-white rounded-md px-4 py-1 mt-2"
// // // // >
// // // //             Add New Queue
// // // // </button>
// // // // <button
// // // //             onClick={handleEditClick}
// // // //             className="bg-purple-900 text-white rounded-md px-4 py-1 ml-2"
// // // // >
// // // //             Submit
// // // // </button>
// // // // </div>
// // // //         {successMessage && <p className="text-green-500">{successMessage}</p>}
// // // // </div>
// // // // </div>
// // // //   );
// // // // }










// // // import React, { useState, useEffect } from 'react';
// // // import Header from '../layout/Header';
// // // import { useLocation, useNavigate } from 'react-router-dom';
// // // import Select from 'react-select';
// // // import config from '../config';
 
// // // export default function EditEWT() {
// // //   const location = useLocation();
// // //   const navigate = useNavigate();
 
// // //   const [initialLobName, setInitialLobName] = useState(location.state ? location.state.lobName : '');
// // //   const [initialListOfQueues, setInitialListOfQueues] = useState([
// // //     { label: 'Initial Queue 1', value: 1 }, // Initial values for the first queue
// // //   ]);
// // //   const [initialQueueNames, setInitialQueueNames] = useState([]);
// // //   const [initialDropdownComponents, setInitialDropdownComponents] = useState([0]);
// // //   const [initialPercentages, setInitialPercentages] = useState([0]); // Initial value for the first queue
// // //   const [initialSuccessMessage, setInitialSuccessMessage] = useState('');
 
// // //   const [lobName, setLobName] = useState(initialLobName);
// // //   const [listOfQueues, setListOfQueues] = useState(initialListOfQueues);
// // //   const [queueNames, setQueueNames] = useState([]);
// // //   const [dropdownComponents, setDropdownComponents] = useState(initialDropdownComponents);
// // //   const [percentages, setPercentages] = useState(initialPercentages);
// // //   const [queueIds, setQueueIds] = useState([]); // Initialize queueIds as an empty array
// // //   const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);
// // //   const [lobNameError, setLobNameError] = useState('');
// // //   const [percentageError, setPercentageError] = useState('');
 
// // //   const customDropdownStyles = {
// // //     control: (provided) => ({
// // //       ...provided,
// // //       width: 300,
// // //     }),
// // //   };
 
// // //   useEffect(() => {
// // //     fetchQueueNames();
// // //     populateFieldsFromURL();
// // //   }, []);
 
// // //   const populateFieldsFromURL = () => {
// // //     const searchParams = new URLSearchParams(location.search);
// // //     const lobNameParam = searchParams.get('lobName');
// // //     if (lobNameParam) {
// // //       setLobName(lobNameParam);
// // //     }
 
// // //     const queueNamesParam = searchParams.get('queueNames');
// // //     const percentagesParam = searchParams.get('percentages');
// // //     let queueidsParam = searchParams.get('queueids');
 
// // //     // Remove empty values from the queueidsParam array
// // //     const queueIdsArray = queueidsParam ? queueidsParam.split(',').filter(Boolean) : [];
 
// // //     setQueueIds(queueIdsArray);
 
// // //     // Default values for queueNames and percentages
// // //     const defaultQueueNames = [{ label: 'Default Queue', value: 1 }];
// // //     const defaultPercentages = [0];
 
// // //     const queueNamesArray = queueNamesParam ? queueNamesParam.split(',') : defaultQueueNames;
// // //     const percentagesArray = percentagesParam ? percentagesParam.split(',') : defaultPercentages;
 
// // //     const selectedQueues = queueNamesArray.map((queueName, index) => ({
// // //       label: queueName,
// // //       value: queueIdsArray.length ? queueIdsArray[index] : index + 1,
// // //     }));
 
// // //     setListOfQueues(selectedQueues);
// // //     setPercentages(percentagesArray);
// // //     setQueueNames(defaultQueueNames); // Set default queue names if not present in the URL
// // //   };
 
// // //   const fetchQueueNames = () => {
// // //     fetch(`${config.apiUrl}/getqueues`, {
// // //       method: 'POST',
// // //       headers: {
// // //        // 'Authorization': 'vJoTXei5oCHgkpbZ5WcNFWQ_u8yPZhsTxvBY3eObG1EFIH1SaexBTLjP0Dg5EgW3lJ_FJnCHajTAlNRvM79Y9ygj0FFdHogaijxUSMRL-H_IIcpCBEF4eEcA91NqSule',
// // //        'Authorization': config.token,
// // //       },
// // //     })
// // //       .then((response) => response.json())
// // //       .then((data) => {
// // //         const options = data.map((queue) => ({
// // //           value: queue.id,
// // //           label: queue.name,
// // //         }));
// // //         setQueueNames(options);
// // //       })
// // //       .catch((error) => {
// // //         console.error('Error fetching queue names:', error);
// // //       });
// // //   };
 
// // //   const handleInputChange = (event) => {
// // //     const value = event.target.value;
// // //     if (/^[a-zA-Z ]*$/.test(value)) {
// // //       setLobName(value);
// // //       setLobNameError('');
// // //     } else {
// // //       setLobNameError('LOB Name should only contain characters.');
// // //     }
// // //   };
 
// // //   const handleQueueChange = (selectedOption, index) => {
// // //     const updatedListOfQueues = [...listOfQueues];
// // //     updatedListOfQueues[index] = selectedOption;
// // //     setListOfQueues(updatedListOfQueues);
// // //   };
 
// // //   const handlePercentageChange = (value, index) => {
// // //     const updatedPercentages = [...percentages];
// // //     updatedPercentages[index] = value;
// // //     setPercentages(updatedPercentages);
// // //   };
// // //   const handleAddNewQueue = () => {
// // //     // Assign a new unique ID for the added queue
// // //     const newQueueId = listOfQueues.length + 1;
 
// // //     // Add a new queue with initial values
// // //     const newQueue = { label: 'New Queue', value: newQueueId };
 
// // //     setListOfQueues([...listOfQueues, newQueue]);
// // //     setQueueIds([...queueIds, newQueueId]);
// // //     setPercentages([...percentages, 0]); // Initial percentage for the new queue
// // //   };
 
 
// // //   const handleEditClick = (event) => {
// // //     event.preventDefault();
 
// // //     if (lobName.trim() === '') {
// // //       setLobNameError('LOB Name is required');
// // //       return;
// // //     }
 
// // //     if (percentageError) {
// // //       return; // Prevent submission if there is a percentage error
// // //     }
 
// // //     // Create an array to store updated queue data
// // //     const updatedQueues = listOfQueues.map((queue, index) => ({
// // //       queuename: queue.label,
// // //       queueid: queueIds[index], // Use the original queueid
// // //       percentage: percentages[index],
// // //     }));
 
// // //     // Your code to handle the edit action, including the percentages
// // //     const requestData = {
// // //       lob: lobName,
// // //       queues: updatedQueues, // Pass the updated queue data
// // //     };
 
// // //     // Submit the requestData to your server endpoint
// // //     fetch(`${config.apiUrl}/editpercentage`, {
// // //       method: 'POST',
// // //       headers: {
// // //         //'Authorization':
// // //         //  'vJoTXei5oCHgkpbZ5WcNFWQ_u8yPZhsTxvBY3eObG1EFIH1SaexBTLjP0Dg5EgW3lJ_FJnCHajTAlNRvM79Y9ygj0FFdHogaijxUSMRL-H_IIcpCBEF4eEcA91NqSule',
// // //         'Authorization': config.token,
// // //         'Content-Type': 'application/json',
// // //       },
// // //       body: JSON.stringify(requestData),
// // //     })
// // //       .then((response) => response.json())
// // //       .then((insertData) => {
// // //         // Handle the response from the API
// // //         setSuccessMessage('Data updated successfully');
// // //         console.log(insertData);
// // //         setTimeout(() => {
// // //           // Use your navigation logic here, for example:
// // //           navigate('/percentage');
// // //         }, 1000);
// // //       })
// // //       .catch((error) => {
// // //         console.error('Error updating EWT configuration:', error);
// // //       });
// // //   };
 
// // //   return (
// // //     <div>
// // //       <Header />
// // //       <div className="flex flex-col items-center">
// // //         <br />
// // //         <div className="flex items-center">
// // //           <label htmlFor="lobName" className="text-center mr-2 font-roboto">
// // //             LOB Name:
// // //           </label>
// // //           <input
// // //             type="text"
// // //             id="lobName"
// // //             className="border border-black-200 px-1 py-0 w-50 text-center"
// // //             placeholder="Enter LOB Name"
// // //             value={lobName}
// // //             onChange={handleInputChange}
// // //             style={{ marginLeft: '50px' }}
// // //           />
// // //         </div>
// // //         {lobNameError && <p className="text-red-500">{lobNameError}</p>}
// // //         <br />
// // //         <br />
// // //         <div>
// // //           {listOfQueues.map((queue, index) => (
// // //             <div
// // //               key={queue.value}
// // //               style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}
// // //             >
// // //               <div style={{ marginRight: '10px' }}>
// // //                 <h2 className="text-l font-semibold mb-2">Queue Name</h2>
// // //                 <Select
// // //                   options={queueNames} // Provide the options from the 'getqueues' API
// // //                   value={queue}
// // //                   onChange={(selectedOption) => handleQueueChange(selectedOption, index)}
// // //                 />
// // //               </div>
// // //               <div>
// // //                 <h2 className="text-l font-semibold mb-2">Percentage</h2>
// // //                 <input
// // //                   type="number"
// // //                   min="0"
// // //                   max="100"
// // //                   value={percentages[index]}
// // //                   onChange={(e) => handlePercentageChange(e.target.value, index)}
// // //                   className="border border-black-200 px-1 py-0 w-50 text-center"
// // //                   placeholder="Enter Percentage"
// // //                 />
// // //               </div>
// // //             </div>
// // //           ))}
// // //         </div>
// // //         {percentageError && <p className="text-red-500">{percentageError}</p>}
// // //         <button
// // //           onClick={handleAddNewQueue}
// // //           className="bg-blue-500 text-white rounded-md px-4 py-1"
// // //         >
// // //           Add New Queue
// // //         </button>
// // //         <br />
// // //         <br />
// // //         <div>
// // //           <button
// // //             onClick={handleEditClick}
// // //             className="bg-purple-900 text-white rounded-md px-4 py-1 ml-2"
// // //           >
// // //             Submit
// // //           </button>
// // //         </div>
// // //         {successMessage && <p className="text-green-500">{successMessage}</p>}
// // //       </div>
// // //     </div>
// // //   );
// // // }
 



// // // import React, { useState, useEffect } from 'react';
// // // import Header from '../layout/Header';
// // // import { useLocation, useNavigate } from 'react-router-dom';
// // // import Select from 'react-select';
// // // // import config from "../config";
 

// // // const CustomOption = ({ innerProps, label }) => (
// // //   <div {...innerProps}>{label}</div>
// // // );

// // // export default function EditEWT() {
// // //   const location = useLocation();
// // //   const navigate = useNavigate();

// // //   const [initialLobName, setInitialLobName] = useState(location.state ? location.state.lobName : '');
// // //   const [initialListOfQueues, setInitialListOfQueues] = useState([
// // //     { label: 'Initial Queue 1', value: 1 }, // Initial values for the first queue
// // //   ]);
// // //   const [initialQueueNames, setInitialQueueNames] = useState([]);
// // //   const [initialDropdownComponents, setInitialDropdownComponents] = useState([0]);
// // //   const [initialPercentages, setInitialPercentages] = useState([0]); // Initial value for the first queue
// // //   const [initialSuccessMessage, setInitialSuccessMessage] = useState('');

// // //   const [lobName, setLobName] = useState(initialLobName);
// // //   const [listOfQueues, setListOfQueues] = useState(initialListOfQueues);
// // //   const [queueNames, setQueueNames] = useState([]);
// // //   const [dropdownComponents, setDropdownComponents] = useState(initialDropdownComponents);
// // //   const [percentages, setPercentages] = useState(initialPercentages);
// // //   const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);
// // //   const [lobNameError, setLobNameError] = useState('');
// // //   const [percentageError, setPercentageError] = useState('');

// // //   const customDropdownStyles = {
// // //     control: (provided) => ({
// // //       ...provided,
// // //       width: 300,
// // //     }),
// // //   };

// // //   useEffect(() => {
// // //     fetchQueueNames();
// // //     populateFieldsFromURL();
// // //   }, []);

// // //   const populateFieldsFromURL = () => {
// // //     const searchParams = new URLSearchParams(location.search);
// // //     const lobNameParam = searchParams.get('lobName');
// // //     if (lobNameParam) {
// // //       setLobName(lobNameParam);
// // //     }

// // //     const queueNamesParam = searchParams.get('queueNames');
// // //     const percentagesParam = searchParams.get('percentages');

// // //     if (queueNamesParam && percentagesParam) {
// // //       const queueNamesArray = queueNamesParam.split(',');
// // //       const percentagesArray = percentagesParam.split(',');

// // //       const selectedQueues = queueNamesArray.map((queueName, index) => ({
// // //         label: queueName,
// // //         value: index,
// // //       }));

// // //       setListOfQueues(selectedQueues);
// // //       setPercentages(percentagesArray);
// // //     }
// // //   };

// // //   const fetchQueueNames = () => {
// // //     fetch('http://localhost:3200/getqueues', {
// // //       method: 'POST',
// // //       headers: {
// // //         'Authorization': 'vJoTXei5oCHgkpbZ5WcNFWQ_u8yPZhsTxvBY3eObG1EFIH1SaexBTLjP0Dg5EgW3lJ_FJnCHajTAlNRvM79Y9ygj0FFdHogaijxUSMRL-H_IIcpCBEF4eEcA91NqSule',
// // //       },
// // //     })
// // //       .then((response) => response.json())
// // //       .then((data) => {
// // //         const options = data.map((queue) => ({
// // //           value: queue.id,
// // //           label: queue.name,
// // //         }));
// // //         setQueueNames(options);
// // //       })
// // //       .catch((error) => {
// // //         console.error('Error fetching queue names:', error);
// // //       });
// // //   };

// // //   const handleInputChange = (event) => {
// // //     const value = event.target.value;
// // //     if (/^[a-zA-Z ]*$/.test(value)) {
// // //       setLobName(value);
// // //       setLobNameError('');
// // //     } else {
// // //       setLobNameError('LOB Name should only contain characters.');
// // //     }
// // //   };

// // //   const handleQueueChange = (selectedOption, index) => {
// // //     const updatedListOfQueues = [...listOfQueues];
// // //     updatedListOfQueues[index] = selectedOption;
// // //     setListOfQueues(updatedListOfQueues);
// // //   };

// // //   const handlePercentageChange = (value, index) => {
// // //     const updatedPercentages = [...percentages];
// // //     updatedPercentages[index] = value;
// // //     setPercentages(updatedPercentages);
// // //   };

// // //   const handleEditClick = (event) => {
// // //     event.preventDefault();

// // //     if (lobName.trim() === '') {
// // //       setLobNameError('LOB Name is required');
// // //       return;
// // //     }

// // //     if (percentageError) {
// // //       return; // Prevent submission if there is a percentage error
// // //     }

// // //     // Create an array to store updated queue data
// // //     const updatedQueues = listOfQueues.map((queue) => ({
// // //       queuename: queue.label,
// // //       queueid: queue.value,
// // //       percentage: percentages[listOfQueues.indexOf(queue)],
// // //     }));

// // //     // Your code to handle the edit action, including the percentages
// // //     const requestData = {
// // //       lob: lobName,
// // //       queues: updatedQueues, // Pass the updated queue data
// // //     };

// // //     // Submit the requestData to your server endpoint
// // //     fetch('http://localhost:3200/editpercentage', {
// // //       method: 'POST',
// // //       headers: {
// // //         'Authorization':
// // //           'vJoTXei5oCHgkpbZ5WcNFWQ_u8yPZhsTxvBY3eObG1EFIH1SaexBTLjP0Dg5EgW3lJ_FJnCHajTAlNRvM79Y9ygj0FFdHogaijxUSMRL-H_IIcpCBEF4eEcA91NqSule',
// // //         'Content-Type': 'application/json',
// // //       },
// // //       body: JSON.stringify(requestData),
// // //     })
// // //       .then((response) => response.json())
// // //       .then((insertData) => {
// // //         // Handle the response from the API
// // //         setSuccessMessage('Data updated successfully');
// // //         console.log(insertData);
// // //         setTimeout(() => {
// // //           // Use your navigation logic here, for example:
// // //           navigate('/percentage');
// // //         }, 1000);
// // //       })
// // //       .catch((error) => {
// // //         console.error('Error updating EWT configuration:', error);
// // //       });
// // //   };

// // //   return (
// // //     <div>
// // //       <Header />
// // //       <div className="flex flex-col items-center">
// // //         <br />
// // //         <div className="flex items-center">
// // //           <label htmlFor="lobName" className="text-center mr-2 font-roboto">
// // //             LOB Name:
// // //           </label>
// // //           <input
// // //             type="text"
// // //             id="lobName"
// // //             className="border border-black-200 px-1 py-0 w-50 text-center"
// // //             placeholder="Enter LOB Name"
// // //             value={lobName}
// // //             onChange={handleInputChange}
// // //             style={{ marginLeft: '50px' }}
// // //           />
// // //         </div>
// // //         {lobNameError && <p className="text-red-500">{lobNameError}</p>}
// // //         <br />
// // //         <br />
// // //         <div>
// // //           {listOfQueues.map((queue, index) => (
// // //             <div
// // //               key={queue.value}
// // //               style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}
// // //             >
// // //               <div style={{ marginRight: '10px' }}>
// // //                 <h2 className="text-l font-semibold mb-2">Queue Name</h2>
// // //                 <Select
// // //                   options={queueNames} // Provide the options from the 'getqueues' API
// // //                   value={queue}
// // //                   onChange={(selectedOption) => handleQueueChange(selectedOption, index)}
// // //                 />
// // //               </div>
// // //               <div>
// // //                 <h2 className="text-l font-semibold mb-2">Percentage</h2>
// // //                 <input
// // //                   type="number"
// // //                   min="0"
// // //                   max="100"
// // //                   value={percentages[index]}
// // //                   onChange={(e) => handlePercentageChange(e.target.value, index)}
// // //                   className="border border-black-200 px-1 py-0 w-50 text-center"
// // //                   placeholder="Enter Percentage"
// // //                 />
// // //               </div>
// // //             </div>
// // //           ))}
// // //         </div>
// // //         {percentageError && <p className="text-red-500">{percentageError}</p>}

// // //         <br />
// // //         <br />
// // //         <div>
// // //           <button
// // //             onClick={handleEditClick}
// // //             className="bg-purple-900 text-white rounded-md px-4 py-1 ml-2"
// // //           >
// // //             Submit
// // //           </button>
// // //         </div>
// // //         {successMessage && <p className="text-green-500">{successMessage}</p>}
// // //       </div>
// // //     </div>
// // //   );
// // // }



// // import React, { useState, useEffect } from 'react';
// // import Header from '../layout/Header';
// // import { useLocation, useNavigate } from 'react-router-dom';
// // import Select from 'react-select';
// //  import config from "../config";
 

// // const CustomOption = ({ innerProps, label }) => (
// //   <div {...innerProps}>{label}</div>
// // );

// // export default function EditEWT() {
// //   const location = useLocation();
// //   const navigate = useNavigate();

// //   const [initialLobName, setInitialLobName] = useState(location.state ? location.state.lobName : '');
// //   const [initialListOfQueues, setInitialListOfQueues] = useState([
// //     { label: 'Initial Queue 1', value: 1 }, // Initial values for the first queue
// //   ]);
// //   const [initialQueueNames, setInitialQueueNames] = useState([]);
// //   const [queueIds, setQueueIds] = useState([])
// //   const [initialDropdownComponents, setInitialDropdownComponents] = useState([0]);
// //   const [initialPercentages, setInitialPercentages] = useState([0]); // Initial value for the first queue
// //   const [initialSuccessMessage, setInitialSuccessMessage] = useState('');

// //   const [lobName, setLobName] = useState(initialLobName);
// //   const [listOfQueues, setListOfQueues] = useState(initialListOfQueues);
// //   const [queueNames, setQueueNames] = useState([]);
// //   const [dropdownComponents, setDropdownComponents] = useState(initialDropdownComponents);
// //   const [percentages, setPercentages] = useState(initialPercentages);
// //   const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);
// //   const [lobNameError, setLobNameError] = useState('');
// //   const [percentageError, setPercentageError] = useState('');

// //   const customDropdownStyles = {
// //     control: (provided) => ({
// //       ...provided,
// //       width: 300,
// //     }),
// //   };

// //   // useEffect(() => {
// //   //   fetchQueueNames();
// //   //   populateFieldsFromURL();
// //   // }, []);

// //   // const populateFieldsFromURL = () => {
// //   //   const searchParams = new URLSearchParams(location.search);
// //   //   const lobNameParam = searchParams.get('lobName');
// //   //   if (lobNameParam) {
// //   //     setLobName(lobNameParam);
// //   //   }

// //   //   const queueNamesParam = searchParams.get('queueNames');
// //   //   const percentagesParam = searchParams.get('percentages');

// //   //   if (queueNamesParam && percentagesParam) {
// //   //     const queueNamesArray = queueNamesParam.split(',');
// //   //     const percentagesArray = percentagesParam.split(',');

// //   //     const selectedQueues = queueNamesArray.map((queueName, index) => ({
// //   //       label: queueName,
// //   //       value: index,
// //   //     }));

// //   //     setListOfQueues(selectedQueues);
// //   //     setPercentages(percentagesArray);
// //   //   }
// //   // };

// //   const handleAddQueueClick = () => {
// //     setDropdownComponents([...dropdownComponents, dropdownComponents.length]);
// //     // Add an empty array for the newly added dropdown
// //     setListOfQueues([...listOfQueues, []]);
// //     // Add an empty percentage for the newly added dropdown
// //     setPercentages([...percentages, 0]);
// //   };

// //  useEffect(() => {
// //     fetchQueueNames();
// //     populateFieldsFromURL();
// //   }, []);
 
// //   const populateFieldsFromURL = () => {
// //     const searchParams = new URLSearchParams(location.search);
// //     const lobNameParam = searchParams.get('lobName');
// //     if (lobNameParam) {
// //       setLobName(lobNameParam);
// //     }
 
// //     const queueNamesParam = searchParams.get('queueNames');
// //     const percentagesParam = searchParams.get('percentages');
// //     let queueidsParam = searchParams.get('queueids');
 
// //     // Remove empty values from the queueidsParam array
// //     const queueIdsArray = queueidsParam ? queueidsParam.split(',').filter(Boolean) : [];
 
// //     setQueueIds(queueIdsArray);
 
// //     // Default values for queueNames and percentages
// //     const defaultQueueNames = [{ label: 'Default Queue', value: 1 }];
// //     const defaultPercentages = [0];
 
// //     const queueNamesArray = queueNamesParam ? queueNamesParam.split(',') : defaultQueueNames;
// //     const percentagesArray = percentagesParam ? percentagesParam.split(',') : defaultPercentages;
 
// //     const selectedQueues = queueNamesArray.map((queueName, index) => ({
// //       label: queueName,
// //       value: queueIdsArray.length ? queueIdsArray[index] : index + 1,
// //     }));
 
// //     setListOfQueues(selectedQueues);
// //     setPercentages(percentagesArray);
// //     setQueueNames(defaultQueueNames); // Set default queue names if not present in the URL
// //   };
// //   const fetchQueueNames = () => {
// //     fetch(`${config.apiUrl}/getqueues`, {
// //       method: 'POST',
// //       headers: {
// //        // 'Authorization': 'vJoTXei5oCHgkpbZ5WcNFWQ_u8yPZhsTxvBY3eObG1EFIH1SaexBTLjP0Dg5EgW3lJ_FJnCHajTAlNRvM79Y9ygj0FFdHogaijxUSMRL-H_IIcpCBEF4eEcA91NqSule',
// //        'Authorization': config.token,
// //       },
// //     })
// //       .then((response) => response.json())
// //       .then((data) => {
// //         const options = data.map((queue) => ({
// //           value: queue.id,
// //           label: queue.name,
// //         }));
// //         setQueueNames(options);
// //       })
// //       .catch((error) => {
// //         console.error('Error fetching queue names:', error);
// //       });
// //   };

// //   const handleInputChange = (event) => {
// //     const value = event.target.value;
// //     if (/^[a-zA-Z ]*$/.test(value)) {
// //       setLobName(value);
// //       setLobNameError('');
// //     } else {
// //       setLobNameError('LOB Name should only contain characters.');
// //     }
// //   };

// //   const handleQueueChange = (selectedOption, index) => {
// //     const updatedListOfQueues = [...listOfQueues];
// //     updatedListOfQueues[index] = selectedOption;
// //     setListOfQueues(updatedListOfQueues);
// //   };

// //   const handlePercentageChange = (value, index) => {
// //     const updatedPercentages = [...percentages];
// //     updatedPercentages[index] = value;
// //     setPercentages(updatedPercentages);
// //   };

// //   const handleEditClick = (event) => {
// //     event.preventDefault();

// //     if (lobName.trim() === '') {
// //       setLobNameError('LOB Name is required');
// //       return;
// //     }

// //     // if (percentageError) {
// //     //   return; // Prevent submission if there is a percentage error
// //     // }
// //     const totalPercentage = percentages.reduce((acc, percentage) => acc + Number(percentage), 0);

// //   // Check if the total percentage is not equal to 100
// //   if (totalPercentage !== 100) {
// //     setSuccessMessage(''); // Clear any previous success message
// //     setPercentageError('Total percentage should be 100');
// //     return;
// //   } else {
// //     setPercentageError(''); // Clear the percentage error if the total is 100
// //   }

// //     // Create an array to store updated queue data
// //     const updatedQueues = listOfQueues.map((queue) => ({
// //       queuename: queue.label,
// //       queueid: queue.value,
// //       percentage: percentages[listOfQueues.indexOf(queue)],
// //     }));

// //     // Your code to handle the edit action, including the percentages
// //     const requestData = {
// //       lob: lobName,
// //       queues: updatedQueues, // Pass the updated queue data
// //     };

// //     // Submit the requestData to your server endpoint
// //     fetch(`${config.apiUrl}/editpercentage`, {
// //       method: 'POST',
// //       headers: {
// //         'Authorization': config.token,
// //        // 'Authorization':
// //         //  'vJoTXei5oCHgkpbZ5WcNFWQ_u8yPZhsTxvBY3eObG1EFIH1SaexBTLjP0Dg5EgW3lJ_FJnCHajTAlNRvM79Y9ygj0FFdHogaijxUSMRL-H_IIcpCBEF4eEcA91NqSule',
// //         'Content-Type': 'application/json',
// //       },
// //       body: JSON.stringify(requestData),
// //     })
// //       .then((response) => response.json())
// //       .then((insertData) => {
// //         // Handle the response from the API
// //         setSuccessMessage('Data updated successfully');
// //         console.log(insertData);
// //         setTimeout(() => {
// //           // Use your navigation logic here, for example:
// //           navigate('/percentage');
// //         }, 1000);
// //       })
// //       .catch((error) => {
// //         console.error('Error updating EWT configuration:', error);
// //       });
// //   };

// //   return (
// //     <div>
// //       <Header />
// //       <div className="flex flex-col items-center">
// //         <br />
// //         <div className="flex items-center">
// //           <label htmlFor="lobName" className="text-center mr-2 font-roboto">
// //             LOB Name:
// //           </label>
// //           <input
// //             type="text"
// //             id="lobName"
// //             className="border border-black-200 px-1 py-0 w-50 text-center"
// //             placeholder="Enter LOB Name"
// //             value={lobName}
// //             onChange={handleInputChange}
// //             style={{ marginLeft: '50px' }}
// //           />
// //         </div>
// //         {lobNameError && <p className="text-red-500">{lobNameError}</p>}
// //         <br />
// //         <br />
// //         <div>
// //           {listOfQueues.map((queue, index) => (
// //             <div
// //               key={queue.value}
// //               style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}
// //             >
// //               <div style={{ marginRight: '10px' }}>
// //                 <h2 className="text-l font-semibold mb-2">Queue Name</h2>
// //                 <Select
// //                   options={queueNames} // Provide the options from the 'getqueues' API
// //                   value={queue}
// //                   onChange={(selectedOption) => handleQueueChange(selectedOption, index)}
// //                   styles={customDropdownStyles}
// //                />
// //               </div>
// //               <div>
// //                 <h2 className="text-l font-semibold mb-2">Percentage</h2>
// //                 <input
// //                   type="number"
// //                   min="0"
// //                   max="100"
// //                   value={percentages[index]}
// //                   onChange={(e) => handlePercentageChange(e.target.value, index)}
// //                   className="border border-black-200 px-1 py-0 w-50 text-center"
// //                   placeholder="Enter Percentage"
// //                 />
// //               </div>
// //             </div>
// //           ))}
// //         </div>
// //         {percentageError && <p className="text-red-500">{percentageError}</p>}

// //         <br />
// //         <br />
// //         <div>
// //         <button
// //             onClick={handleAddQueueClick}
// //             className="bg-purple-900 text-white rounded-md px-4 py-1 mt-2"
// //           >
// //             Add New Queue
// //           </button>
// //           <button
// //             onClick={handleEditClick}
// //             className="bg-purple-900 text-white rounded-md px-4 py-1 ml-2"
// //           >
// //             Submit
// //           </button>
// //         </div>
// //         {successMessage && <p className="text-green-500">{successMessage}</p>}
// //       </div>
// //     </div>
// //   );
// // }
// import React, { useState, useEffect } from 'react';
// import Header from '../layout/Header';
// import { useLocation, useNavigate } from 'react-router-dom';
// import Select from 'react-select';
//  import config from "../config";
 

// const CustomOption = ({ innerProps, label }) => (
//   <div {...innerProps}>{label}</div>
// );

// export default function EditEWT() {
//   const location = useLocation();
//   const navigate = useNavigate();

//   const [initialLobName, setInitialLobName] = useState(location.state ? location.state.lobName : '');
//   const [initialListOfQueues, setInitialListOfQueues] = useState([
//     { label: 'Initial Queue 1', value: 1 }, // Initial values for the first queue
//   ]);
//   const [initialQueueNames, setInitialQueueNames] = useState([]);
//   const [queueIds, setQueueIds] = useState([])
//   const [initialDropdownComponents, setInitialDropdownComponents] = useState([0]);
//   const [initialPercentages, setInitialPercentages] = useState([0]); // Initial value for the first queue
//   const [initialSuccessMessage, setInitialSuccessMessage] = useState('');

//   const [lobName, setLobName] = useState(initialLobName);
//   const [listOfQueues, setListOfQueues] = useState(initialListOfQueues);
//   const [queueNames, setQueueNames] = useState([]);
//   const [dropdownComponents, setDropdownComponents] = useState(initialDropdownComponents);
//   const [percentages, setPercentages] = useState(initialPercentages);
//   const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);
//   const [lobNameError, setLobNameError] = useState('');
//   const [percentageError, setPercentageError] = useState('');

//   const customDropdownStyles = {
//     control: (provided) => ({
//       ...provided,
//       width: 300,
//     }),
//   };

//   // useEffect(() => {
//   //   fetchQueueNames();
//   //   populateFieldsFromURL();
//   // }, []);

//   // const populateFieldsFromURL = () => {
//   //   const searchParams = new URLSearchParams(location.search);
//   //   const lobNameParam = searchParams.get('lobName');
//   //   if (lobNameParam) {
//   //     setLobName(lobNameParam);
//   //   }

//   //   const queueNamesParam = searchParams.get('queueNames');
//   //   const percentagesParam = searchParams.get('percentages');

//   //   if (queueNamesParam && percentagesParam) {
//   //     const queueNamesArray = queueNamesParam.split(',');
//   //     const percentagesArray = percentagesParam.split(',');

//   //     const selectedQueues = queueNamesArray.map((queueName, index) => ({
//   //       label: queueName,
//   //       value: index,
//   //     }));

//   //     setListOfQueues(selectedQueues);
//   //     setPercentages(percentagesArray);
//   //   }
//   // };

//   const handleAddQueueClick = () => {
//     setDropdownComponents([...dropdownComponents, dropdownComponents.length]);
//     // Add an empty array for the newly added dropdown
//     setListOfQueues([...listOfQueues, []]);
//     // Add an empty percentage for the newly added dropdown
//     setPercentages([...percentages, 0]);
//   };

//  useEffect(() => {
//     fetchQueueNames();
//     populateFieldsFromURL();
//   }, []);
 
//   const populateFieldsFromURL = () => {
//     const searchParams = new URLSearchParams(location.search);
//     const lobNameParam = searchParams.get('lobName');
//     if (lobNameParam) {
//       setLobName(lobNameParam);
//     }
 
//     const queueNamesParam = searchParams.get('queueNames');
//     const percentagesParam = searchParams.get('percentages');
//     let queueidsParam = searchParams.get('queueids');
 
//     // Remove empty values from the queueidsParam array
//     const queueIdsArray = queueidsParam ? queueidsParam.split(',').filter(Boolean) : [];
 
//     setQueueIds(queueIdsArray);
 
//     // Default values for queueNames and percentages
//     const defaultQueueNames = [{ label: 'Default Queue', value: 1 }];
//     const defaultPercentages = [0];
 
//     const queueNamesArray = queueNamesParam ? queueNamesParam.split(',') : defaultQueueNames;
//     const percentagesArray = percentagesParam ? percentagesParam.split(',') : defaultPercentages;
 
//     const selectedQueues = queueNamesArray.map((queueName, index) => ({
//       label: queueName,
//       value: queueIdsArray.length ? queueIdsArray[index] : index + 1,
//     }));
 
//     setListOfQueues(selectedQueues);
//     setPercentages(percentagesArray);
//     setQueueNames(defaultQueueNames); // Set default queue names if not present in the URL
//   };
//   const fetchQueueNames = () => {
//     fetch(`${config.apiUrl}/getqueues`, {
//       method: 'POST',
//       headers: {
//        // 'Authorization': 'vJoTXei5oCHgkpbZ5WcNFWQ_u8yPZhsTxvBY3eObG1EFIH1SaexBTLjP0Dg5EgW3lJ_FJnCHajTAlNRvM79Y9ygj0FFdHogaijxUSMRL-H_IIcpCBEF4eEcA91NqSule',
//        'Authorization': config.token,
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         const options = data.map((queue) => ({
//           value: queue.id,
//           label: queue.name,
//         }));
//         setQueueNames(options);
//       })
//       .catch((error) => {
//         console.error('Error fetching queue names:', error);
//       });
//   };

//   const handleInputChange = (event) => {
//     const value = event.target.value;
//     if (/^[a-zA-Z ]*$/.test(value)) {
//       setLobName(value);
//       setLobNameError('');
//     } else {
//       setLobNameError('LOB Name should only contain characters.');
//     }
//   };

//   const handleQueueChange = (selectedOption, index) => {
//     const updatedListOfQueues = [...listOfQueues];
//     updatedListOfQueues[index] = selectedOption;
//     setListOfQueues(updatedListOfQueues);
//   };

//   const handlePercentageChange = (value, index) => {
//     const updatedPercentages = [...percentages];
//     updatedPercentages[index] = value;
//     setPercentages(updatedPercentages);
//   };

//   const handleEditClick = (event) => {
//     event.preventDefault();

//     if (lobName.trim() === '') {
//       setLobNameError('LOB Name is required');
//       return;
//     }

//     // if (percentageError) {
//     //   return; // Prevent submission if there is a percentage error
//     // }
//     const totalPercentage = percentages.reduce((acc, percentage) => acc + Number(percentage), 0);

//   // Check if the total percentage is not equal to 100
//   if (totalPercentage !== 100) {
//     setSuccessMessage(''); // Clear any previous success message
//     setPercentageError('Total percentage should be 100');
//     return;
//   } else {
//     setPercentageError(''); // Clear the percentage error if the total is 100
//   }

//     // Create an array to store updated queue data
//     const updatedQueues = listOfQueues.map((queue) => ({
//       queuename: queue.label,
//       queueid: queue.value,
//       percentage: percentages[listOfQueues.indexOf(queue)],
//     }));

//     // Your code to handle the edit action, including the percentages
//     const requestData = {
//       lob: lobName,
//       queues: updatedQueues, // Pass the updated queue data
//     };

//     // Submit the requestData to your server endpoint
//     fetch(`${config.apiUrl}/editpercentage`, {
//       method: 'POST',
//       headers: {
//         'Authorization': config.token,
//        // 'Authorization':
//         //  'vJoTXei5oCHgkpbZ5WcNFWQ_u8yPZhsTxvBY3eObG1EFIH1SaexBTLjP0Dg5EgW3lJ_FJnCHajTAlNRvM79Y9ygj0FFdHogaijxUSMRL-H_IIcpCBEF4eEcA91NqSule',
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(requestData),
//     })
//       .then((response) => response.json())
//       .then((insertData) => {
//         // Handle the response from the API
//         setSuccessMessage('Data updated successfully');
//         console.log(insertData);
//         setTimeout(() => {
//           // Use your navigation logic here, for example:
//           navigate('/percentage');
//         }, 1000);
//       })
//       .catch((error) => {
//         console.error('Error updating EWT configuration:', error);
//       });
//   };

//   return (
//     <div>
//       <Header />
//       <div className="flex flex-col items-center">
//         <br />
//         <div className="flex items-center">
//           <label htmlFor="lobName" className="text-center mr-2 font-roboto">
//             LOB Name:
//           </label>
//           <input
//             type="text"
//             id="lobName"
//             className="border border-black-200 px-1 py-0 w-50 text-center"
//             placeholder="Enter LOB Name"
//             value={lobName}
//             onChange={handleInputChange}
//             style={{ marginLeft: '50px' }}
//           />
//         </div>
//         {lobNameError && <p className="text-red-500">{lobNameError}</p>}
//         <br />
//         <br />
//         <div>
//           {listOfQueues.map((queue, index) => (
//             <div
//               key={queue.value}
//               style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}
//             >
//               <div style={{ marginRight: '10px' }}>
//                 <h2 className="text-l font-semibold mb-2">Queue Name</h2>
//                 <Select
//                   options={queueNames} // Provide the options from the 'getqueues' API
//                   value={queue}
//                   onChange={(selectedOption) => handleQueueChange(selectedOption, index)}
//                   styles={customDropdownStyles}
//                 />
//               </div>
//               <div>
//                 <h2 className="text-l font-semibold mb-2">Percentage</h2>
//                 <input
//                   type="number"
//                   min="0"
//                   max="100"
//                   value={percentages[index]}
//                   onChange={(e) => handlePercentageChange(e.target.value, index)}
//                   className="border border-black-200 px-1 py-0 w-50 text-center"
//                   placeholder="Enter Percentage"
//                 />
//               </div>
//             </div>
//           ))}
//         </div>
//         {percentageError && <p className="text-red-500">{percentageError}</p>}

//         <br />
//         <br />
//         <div>
//         <button
//             onClick={handleAddQueueClick}
//             className="bg-purple-900 text-white rounded-md px-4 py-1 mt-2"
//           >
//             Add New Queue
//           </button>
//           <button
//             onClick={handleEditClick}
//             className="bg-purple-900 text-white rounded-md px-4 py-1 ml-2"
//           >
//             Submit
//           </button>
//         </div>
//         {successMessage && <p className="text-green-500">{successMessage}</p>}
//       </div>
//     </div>
//   );
// }
// import React, { useState, useEffect } from 'react';
// import Header from '../layout/Header';
// import { useLocation, useNavigate } from 'react-router-dom';
// import Select from 'react-select';
//  import config from "../config";
 

// const CustomOption = ({ innerProps, label }) => (
//   <div {...innerProps}>{label}</div>
// );

// export default function EditEWT() {
//   const location = useLocation();
//   const navigate = useNavigate();

//   const [initialLobName, setInitialLobName] = useState(location.state ? location.state.lobName : '');
//   const [initialListOfQueues, setInitialListOfQueues] = useState([
//     { label: 'Initial Queue 1', value: 1 }, // Initial values for the first queue
//   ]);
//   const [initialQueueNames, setInitialQueueNames] = useState([]);
//   const [queueIds, setQueueIds] = useState([])
//   const [initialDropdownComponents, setInitialDropdownComponents] = useState([0]);
//   const [initialPercentages, setInitialPercentages] = useState([0]); // Initial value for the first queue
//   const [initialSuccessMessage, setInitialSuccessMessage] = useState('');

//   const [lobName, setLobName] = useState(initialLobName);
//   const [listOfQueues, setListOfQueues] = useState(initialListOfQueues);
//   const [queueNames, setQueueNames] = useState([]);
//   const [dropdownComponents, setDropdownComponents] = useState(initialDropdownComponents);
//   const [percentages, setPercentages] = useState(initialPercentages);
//   const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);
//   const [lobNameError, setLobNameError] = useState('');
//   const [percentageError, setPercentageError] = useState('');

//   const customDropdownStyles = {
//     control: (provided) => ({
//       ...provided,
//       width: 300,
//     }),
//   };

//   // useEffect(() => {
//   //   fetchQueueNames();
//   //   populateFieldsFromURL();
//   // }, []);

//   // const populateFieldsFromURL = () => {
//   //   const searchParams = new URLSearchParams(location.search);
//   //   const lobNameParam = searchParams.get('lobName');
//   //   if (lobNameParam) {
//   //     setLobName(lobNameParam);
//   //   }

//   //   const queueNamesParam = searchParams.get('queueNames');
//   //   const percentagesParam = searchParams.get('percentages');

//   //   if (queueNamesParam && percentagesParam) {
//   //     const queueNamesArray = queueNamesParam.split(',');
//   //     const percentagesArray = percentagesParam.split(',');

//   //     const selectedQueues = queueNamesArray.map((queueName, index) => ({
//   //       label: queueName,
//   //       value: index,
//   //     }));

//   //     setListOfQueues(selectedQueues);
//   //     setPercentages(percentagesArray);
//   //   }
//   // };
//   const handleAddQueueClick = () => {
//     setDropdownComponents([...dropdownComponents, dropdownComponents.length]);
  
//     // Add an empty object for the newly added queue
//     setListOfQueues([...listOfQueues, {}]);
  
//     // Add an empty percentage for the newly added queue
//     setPercentages([...percentages, '']); // Assuming percentage can be a string
  
//     // Optionally, you can add a default queue name or leave it as an empty string
//     setQueueNames([...queueNames, { label: '', value: dropdownComponents.length }]);
    
//   };
  
  

//  useEffect(() => {
//     fetchQueueNames();
//     populateFieldsFromURL();
//   }, []);
 
//   // const populateFieldsFromURL = () => {
//   //   const searchParams = new URLSearchParams(location.search);
//   //   const lobNameParam = searchParams.get('lobName');
//   //   if (lobNameParam) {
//   //     setLobName(lobNameParam);
//   //   }
 
//   //   const queueNamesParam = searchParams.get('queueNames');
//   //   const percentagesParam = searchParams.get('percentages');
//   //   let queueidsParam = searchParams.get('queueids');
 
//   //   // Remove empty values from the queueidsParam array
//   //   const queueIdsArray = queueidsParam ? queueidsParam.split(',').filter(Boolean) : [];
 
//   //   setQueueIds(queueIdsArray);
 
//   //   // Default values for queueNames and percentages
//   //   const defaultQueueNames = [{ label: 'Default Queue', value: 1 }];
//   //   const defaultPercentages = [0];
 
//   //   const queueNamesArray = queueNamesParam ? queueNamesParam.split(',') : defaultQueueNames;
//   //   const percentagesArray = percentagesParam ? percentagesParam.split(',') : defaultPercentages;
 
//   //   const selectedQueues = queueNamesArray.map((queueName, index) => ({
//   //     label: queueName,
//   //     value: queueIdsArray.length ? queueIdsArray[index] : index + 1,
//   //   }));
 
//   //   setListOfQueues(selectedQueues);
//   //   setPercentages(percentagesArray);
//   //   setQueueNames(defaultQueueNames); // Set default queue names if not present in the URL
//   // };


//   const populateFieldsFromURL = () => {
//     const searchParams = new URLSearchParams(location.search);
//     const lobNameParam = searchParams.get('lobName');
//     if (lobNameParam) {
//       setLobName(lobNameParam);
//     }

//     const queueNamesParam = searchParams.get('queueNames');
//     const percentagesParam = searchParams.get('percentages');
//     let queueidsParam = searchParams.get('queueids');

//     // Remove empty values from the queueidsParam array
//     const queueIdsArray = queueidsParam ? queueidsParam.split(',').filter(Boolean) : [];

//     setQueueIds(queueIdsArray);

//     // Default values for queueNames and percentages
//     const defaultQueueNames = [{ label: 'Default Queue', value: 1 }];
//     const defaultPercentages = [0];

//     const queueNamesArray = queueNamesParam ? queueNamesParam.split(',') : defaultQueueNames;
//     const percentagesArray = percentagesParam ? percentagesParam.split(',') : defaultPercentages;

//     const selectedQueues = queueNamesArray.map((queueName, index) => ({
//       label: queueName,
//       value: queueIdsArray.length ? queueIdsArray[index] : index + 1,
//     }));

//     setListOfQueues(selectedQueues);
//     setPercentages(percentagesArray);
//     setQueueNames(defaultQueueNames); // Set default queue names if not present in the URL
//   };
//   const fetchQueueNames = () => {
//     fetch(`${config.apiUrl}/getqueues`, {
//       method: 'POST',
//       headers: {
//        // 'Authorization': 'vJoTXei5oCHgkpbZ5WcNFWQ_u8yPZhsTxvBY3eObG1EFIH1SaexBTLjP0Dg5EgW3lJ_FJnCHajTAlNRvM79Y9ygj0FFdHogaijxUSMRL-H_IIcpCBEF4eEcA91NqSule',
//        'Authorization': config.token,
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         const options = data.map((queue) => ({
//           value: queue.id,
//           label: queue.name,
//         }));
//         setQueueNames(options);
//       })
//       .catch((error) => {
//         console.error('Error fetching queue names:', error);
//       });
//   };

//   const handleInputChange = (event) => {
//     const value = event.target.value;
//     if (/^[a-zA-Z ]*$/.test(value)) {
//       setLobName(value);
//       setLobNameError('');
//     } else {
//       setLobNameError('LOB Name should only contain characters.');
//     }
//   };

//   const handleQueueChange = (selectedOption, index) => {
//     const updatedListOfQueues = [...listOfQueues];
  
//     // Update the selected queue for the specified index
//     updatedListOfQueues[index] = selectedOption;
  
//     // Check if the selected queue has a label (queue name)
//     if (selectedOption.label) {
//       // If it has a label, set the default percentage to 0
//       setPercentages([...percentages, ...Array.from({ length: index - percentages.length }, () => 0)]);
//     } else {
//       // If it does not have a label (blank), set the default percentage to an empty string
//       setPercentages([...percentages, ...Array.from({ length: index - percentages.length }, () => '')]);
//     }
  
//     // Update the list of queues
//     setListOfQueues(updatedListOfQueues);
//   };
  

//   const handlePercentageChange = (value, index) => {
//     const updatedPercentages = [...percentages];
//     updatedPercentages[index] = value;
//     setPercentages(updatedPercentages);
//   };

//   const handleEditClick = (event) => {
//     event.preventDefault();

//     if (lobName.trim() === '') {
//       setLobNameError('LOB Name is required');
//       return;
//     }

//     // if (percentageError) {
//     //   return; // Prevent submission if there is a percentage error
//     // }
//     const totalPercentage = percentages.reduce((acc, percentage) => acc + Number(percentage), 0);

//   // Check if the total percentage is not equal to 100
//   if (totalPercentage !== 100) {
//     setSuccessMessage(''); // Clear any previous success message
//     setPercentageError('Total percentage should be 100');
//     return;
//   } else {
//     setPercentageError(''); // Clear the percentage error if the total is 100
//   }

//     // Create an array to store updated queue data
//     const updatedQueues = listOfQueues.map((queue) => ({
//       queuename: queue.label,
//       queueid: queue.value,
//       percentage: percentages[listOfQueues.indexOf(queue)],
//     }));

//     // Your code to handle the edit action, including the percentages
//     const requestData = {
//       lob: lobName,
//       queues: updatedQueues, // Pass the updated queue data
//     };

//     // Submit the requestData to your server endpoint
//     fetch(`${config.apiUrl}/editpercentage`, {
//       method: 'POST',
//       headers: {
//         'Authorization': config.token,
//        // 'Authorization':
//         //  'vJoTXei5oCHgkpbZ5WcNFWQ_u8yPZhsTxvBY3eObG1EFIH1SaexBTLjP0Dg5EgW3lJ_FJnCHajTAlNRvM79Y9ygj0FFdHogaijxUSMRL-H_IIcpCBEF4eEcA91NqSule',
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(requestData),
//     })
//       .then((response) => response.json())
//       .then((insertData) => {
//         // Handle the response from the API
//         setSuccessMessage('Data updated successfully');
//         console.log(insertData);
//         setTimeout(() => {
//           // Use your navigation logic here, for example:
//           navigate('/percentage');
//         }, 1000);
//       })
//       .catch((error) => {
//         console.error('Error updating EWT configuration:', error);
//       });
//   };

//   return (
//     <div>
//       <Header />
//       <div className="flex flex-col items-center">
//         <br />
//         <div className="flex items-center">
//           <label htmlFor="lobName" className="text-center mr-2 font-roboto">
//             LOB Name:   <span style={{ color: "red" }}> * </span>
//           </label>
//           <input
//             type="text"
//             id="lobName"
//             className="border border-black-200 px-1 py-1 w-50 text-center"
//             placeholder="Enter LOB Name"
//             value={lobName}
//             onChange={handleInputChange}
//             style={{ marginLeft: '10px' }}
//           />
//         </div>
//         {lobNameError && <p className="text-red-500">{lobNameError}</p>}
//         <br />
//         <br />
//         <div>
//           {listOfQueues.map((queue, index) => (
//             <div
//               key={queue.value}
//               style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}
//             >
//               <div style={{ marginRight: '10px' }}>
//                 <h2 className="text-l font-semibold mb-2">Queue Name <span style={{ color: "red" }}> * </span></h2>
//                 <Select
//                   options={queueNames} // Provide the options from the 'getqueues' API
//                   value={queue}
//                   onChange={(selectedOption) => handleQueueChange(selectedOption, index)}
//                   styles={customDropdownStyles}
//                 />
                
//               </div>
//               <div>
//                 <h2 className="text-l font-semibold mb-2">Percentage <span style={{ color: "red" }}> *  </span></h2>
//                 <input
//                   type="number"
//                   min="0"
//                   max="100"
//                   value={percentages[index]}
//                   onChange={(e) => handlePercentageChange(e.target.value, index)}
//                   className="border border-black-200 px-1 py-0 w-50 text-center"
//                   placeholder="Enter Percentage"
//                 />  % 
//               </div>
//             </div>
//           ))}
//         </div>
//         {percentageError && <p className="text-red-500">{percentageError}</p>}

//         <br />
//         <br />
//         <div>
//         <button
//             onClick={handleAddQueueClick}
//             className="bg-purple-900 text-white rounded-md px-4 py-1 mt-2"
//           >
//             Add New Queue
//           </button>
//           <button
//             onClick={handleEditClick}
//             className="bg-purple-900 text-white rounded-md px-4 py-1 ml-2"
//           >
//             Submit
//           </button>
//         </div>
//         {successMessage && <p className="text-green-500">{successMessage}</p>}
//       </div>
//     </div>
//   );
// }



import React, { useState, useEffect } from 'react';
import Header from '../layout/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import config from "../config";

const CustomOption = ({ innerProps, label }) => (
  <div {...innerProps}>{label}</div>
);

export default function EditEWT() {
  const location = useLocation();
  const navigate = useNavigate();

  const [initialLobName, setInitialLobName] = useState(location.state ? location.state.lobName : '');
  const [initialListOfQueues, setInitialListOfQueues] = useState([
    { label: 'Initial Queue 1', value: 1 }, // Initial values for the first queue
  ]);
  const [initialQueueNames, setInitialQueueNames] = useState([]);
  const [queueIds, setQueueIds] = useState([])
  const [initialDropdownComponents, setInitialDropdownComponents] = useState([0]);
  const [initialPercentages, setInitialPercentages] = useState([0]); // Initial value for the first queue
  const [initialSuccessMessage, setInitialSuccessMessage] = useState('');

  const [lobName, setLobName] = useState(initialLobName);
  const [listOfQueues, setListOfQueues] = useState(initialListOfQueues);
  const [queueNames, setQueueNames] = useState([]);
  const [dropdownComponents, setDropdownComponents] = useState(initialDropdownComponents);
  const [percentages, setPercentages] = useState(initialPercentages);
  const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);
  const [lobNameError, setLobNameError] = useState('');
  const [percentageError, setPercentageError] = useState('');

  // const customDropdownStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     width: 300,
      
  //   }),
  // };
  const customDropdownStyles = {
    control: (provided) => ({
      ...provided,
      width: 300,
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer', // Add this line to set the cursor to a hand pointer
    }),
  };

  const handleRemoveQueueClick = (index) => {
    const updatedListOfQueues = [...listOfQueues];
    updatedListOfQueues.splice(index, 1);

    const updatedPercentages = [...percentages];
    updatedPercentages.splice(index, 1);

    const updatedQueueNames = [...queueNames];
    updatedQueueNames.splice(index, 1);

    setListOfQueues(updatedListOfQueues);
    setPercentages(updatedPercentages);
    setQueueNames(updatedQueueNames);
  };

  useEffect(() => {
    fetchQueueNames();
    populateFieldsFromURL();
  }, []);

  const populateFieldsFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    const lobNameParam = searchParams.get('lobName');
    if (lobNameParam) {
      setLobName(lobNameParam);
    }

    const queueNamesParam = searchParams.get('queueNames');
    const percentagesParam = searchParams.get('percentages');
    let queueidsParam = searchParams.get('queueids');

    const queueIdsArray = queueidsParam ? queueidsParam.split(',').filter(Boolean) : [];

    setQueueIds(queueIdsArray);

    const defaultQueueNames = [{ label: 'Default Queue', value: 1 }];
    const defaultPercentages = [0];

    const queueNamesArray = queueNamesParam ? queueNamesParam.split(',') : defaultQueueNames;
    const percentagesArray = percentagesParam ? percentagesParam.split(',') : defaultPercentages;

    const selectedQueues = queueNamesArray.map((queueName, index) => ({
      label: queueName,
      value: queueIdsArray.length ? queueIdsArray[index] : index + 1,
    }));

    setListOfQueues(selectedQueues);
    setPercentages(percentagesArray);
    setQueueNames(defaultQueueNames);
  };

  const fetchQueueNames = () => {
    fetch(`${config.apiUrl}/getqueues`, {
      method: 'POST',
      headers: {
        'Authorization': config.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((queue) => ({
          value: queue.id,
          label: queue.name,
        }));
        setQueueNames(options);
      })
      .catch((error) => {
        console.error('Error fetching queue names:', error);
      });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (/^[a-zA-Z ]*$/.test(value)) {
      setLobName(value);
      setLobNameError('');
    } else {
      setLobNameError('LOB Name should only contain characters.');
    }
  };

  const handleQueueChange = (selectedOption, index) => {
    const updatedListOfQueues = [...listOfQueues];
    updatedListOfQueues[index] = selectedOption;

    if (selectedOption.label) {
      setPercentages([...percentages, ...Array.from({ length: index - percentages.length }, () => 0)]);
    } else {
      setPercentages([...percentages, ...Array.from({ length: index - percentages.length }, () => '')]);
    }

    setListOfQueues(updatedListOfQueues);
  };

  const handlePercentageChange = (value, index) => {
    const updatedPercentages = [...percentages];
    updatedPercentages[index] = value;
    setPercentages(updatedPercentages);
  };

  const handleAddQueueClick = () => {
    const newIndex = listOfQueues.length;

    setListOfQueues([...listOfQueues, { label: '', value: newIndex }]);
    setPercentages([...percentages, '']);
    setQueueNames([...queueNames, { label: '', value: newIndex }]);
  };

  const handleEditClick = (event) => {
    event.preventDefault();

    if (lobName.trim() === '') {
      setLobNameError('LOB Name is required');
      return;
    }

    const totalPercentage = percentages.reduce((acc, percentage) => acc + Number(percentage), 0);

    if (totalPercentage !== 100) {
      setSuccessMessage('');
      setPercentageError('Total percentage should be 100');
      return;
    } else {
      setPercentageError('');
    }

    const updatedQueues = listOfQueues.map((queue) => ({
      queuename: queue.label,
      queueid: queue.value,
      percentage: percentages[listOfQueues.indexOf(queue)],
    }));

    const requestData = {
      lob: lobName,
      queues: updatedQueues,
    };

    fetch(`${config.apiUrl}/editpercentage`, {
      method: 'POST',
      headers: {
        'Authorization': config.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((insertData) => {
        setSuccessMessage('Data updated successfully');
        console.log(insertData);
        setTimeout(() => {
          navigate('/percentage');
        }, 1000);
      })
      .catch((error) => {
        console.error('Error updating EWT configuration:', error);
      });
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center">
        <br />
        <div className="flex items-center">
          <label htmlFor="lobName" className="text-center mr-2 font-roboto">
            LOB Name:   <span style={{ color: "red" }}> * </span>
          </label>
          <input
            type="text"
            id="lobName"
            className="border border-black-200 px-1 py-1 w-50 text-center"
            placeholder="Enter LOB Name"
            value={lobName}
            onChange={handleInputChange}
            style={{ marginLeft: '10px' }}
          />
        </div>
        {lobNameError && <p className="text-red-500">{lobNameError}</p>}
        <br />
        <br />
        <div>
          {listOfQueues.map((queue, index) => (
            <div
              key={index}
              style={{ marginBottom: '10px',cursor: "pointer", display: 'flex', alignItems: 'center' }}
            >
              <div style={{ marginRight: '10px'  }}>
                <h2 className="text-l font-semibold mb-2">Queue Name <span style={{ color: "red" }}> * </span></h2>
                <Select
                  options={queueNames}
                  value={queue}
                  onChange={(selectedOption) => handleQueueChange(selectedOption, index)}
                  styles={customDropdownStyles}
               
                />
              </div>
              <div>
                <h2 className="text-l font-semibold mb-2">Percentage <span style={{ color: "red" }}> *  </span></h2>
                <input
                  type="number"
                  min="0"
                  max="100"
                  value={percentages[index]}
                  onChange={(e) => handlePercentageChange(e.target.value, index)}
                  // className="border border-black-200 px-1 py-0 w-50 text-center"
                  className="border border-black-200 px-1 py-0 w-50 text-center enlarged-input"
                   placeholder="Enter Percentage"
                />   % 
              </div>
              <div>
                <button
                  onClick={() => handleRemoveQueueClick(index)}
                  className="bg-purple-900 text-white rounded-md mt-8 px-2 py-1 ml-2"
                >
                  Remove Queue
                </button>
              </div>
              
            </div>
          ))}
        </div>
        {percentageError && <p className="text-red-500">{percentageError}</p>}

        <br />
        <br />
        <div>
          <button
            onClick={handleAddQueueClick}
            className="bg-purple-900 text-white rounded-md px-4 py-1 mt-2"
          >
            Add New Queue
          </button>
          <button
            onClick={handleEditClick}
            className="bg-purple-900 text-white rounded-md px-4 py-1 ml-2"
          >
            Submit
          </button>
        </div>
        {successMessage && <p className="text-green-500">{successMessage}</p>}
      </div>
    </div>
  );
}
