

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import img from "../../assets/opti.png";
import im from "../../assets/callrouting.jpg";
import "./Header.css";

const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [showPercentageSubMenu, setShowPercentageSubMenu] = useState(false);
  const [showewtSubMenu, setShowewtSubMenu] = useState(false);
  const navigate = useNavigate();

  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  const handleLogout = () => {
    localStorage.removeItem("loggedin");
    navigate("/login");
  };

  const handlePercentageClick = () => {
    navigate("/percentage");
  };

  const handleEWTClick = () => {
    navigate("/EWT");
  };

  return (
    <>
      <div className="page-wrapper">
        <div id="header">
          <SidebarHeader>
            <div className="logotext">
              <div className="flex justify-center h-20">
                <img src={img} className="App-logo w-full" alt="logo" />
              </div>
              <p>{menuCollapse ? "Logo" : ""}</p>
            </div>
          </SidebarHeader>
        </div>
        <nav className="navbar navbar-light bg-gray-400 justify-content-right">
          <div className="Nav-heading d-flex col align-items-center">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasLightNavbar"
              aria-controls="offcanvasLightNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="navlink">
            <div
              className={`relative inline-block ${
                currentPath === "/EWT" ? "active" : ""
              }`}
              onMouseEnter={() => {
                setShowewtSubMenu(true);
                setShowPercentageSubMenu(false);
              }}
              onMouseLeave={() => setShowewtSubMenu(false)}
              style={{ marginLeft: "50px" }}
            >
              <button
                className={`text-black py-2 px-4 rounded-full cursor-pointer ${
                  currentPath === "/EWT" ? "active-link" : ""
                }`}
                onClick={handleEWTClick}
              >
                EWT
              </button>
              {/* {showewtSubMenu && (
                <div className="percentage-submenu absolute left-0 mt-0 bg-white border border-gray-200 rounded-md shadow-lg">
                  <a href="AddEWT" className="block px-12 py-2 hover:bg-gray-100">
                    Add EWT
                  </a>
                </div>
              )} */}
              {showewtSubMenu && (
  <div className="percentage-submenu absolute left-0 mt-0 bg-white border border-gray-200 rounded-md shadow-lg">
    <a href="/AddEWT" className="block px-12 py-2 hover:bg-gray-100">
      Add EWT
    </a>
  </div>
)}
            </div>

            <div
              className={`relative inline-block ${
                currentPath === "/percentage" ? "active" : ""
              }`}
              onMouseEnter={() => {
                setShowPercentageSubMenu(true);
                setShowewtSubMenu(false);
              }}
              onMouseLeave={() => setShowPercentageSubMenu(false)}
              style={{ marginLeft: "50px" }}
            >
              <button
                className={`text-black py-2 px-4 rounded-full cursor-pointer ${
                  currentPath === "/percentage" ? "active-link" : ""
                }`}
                onClick={handlePercentageClick}
              >
                Percentage
              </button>
              {/* {showPercentageSubMenu && (
                <div className="percentage-submenu absolute left-0 mt-0 bg-white border border-gray-200 rounded-md shadow-lg">
                  <a
                    href="addpercentage"
                    className="block px-12 py-2 hover:bg-gray-100"
                  >
                    Add Percentage
                  </a>
                </div>
              )} */}
              
              {showPercentageSubMenu && (
  <div className="percentage-submenu absolute left-0 mt-0 bg-white border border-gray-200 rounded-md shadow-lg">
    <a href="/addpercentage" className="block px-12 py-2 hover:bg-gray-100">
      Add Percentage
    </a>
  </div>
)}
            </div>
          </div>
        </nav>
       
      </div>
      {/* <div className="image-container">
          {/* <img src="src\assets\basedrouting.PNG" alt="based routing" className="w-full" /> */}
          {/* <img src={im} className="App-logo w-full" alt="logo" />
        </div> */} 
       {currentPath === "/" && (
  <>
    <div className="header-above-image">
      <h1>Percentage Based Call Routing</h1>
      {/* Add any other header content as needed */}
    </div>
    <div className="flex justify-center items-center">
      {/* <img
        src={im}
        className="App-logo max-w-full max-h-full mt-2 ml-1 mr-5 mb-2"
        alt="dashboard"
      /> */}
      <img
  src={im}
  className="App-logo max-w-full max-h-full mt-2 ml-1 mr-5 mb-2"
  style={{ width: "600px", height: "auto" }}
  alt="dashboard"
/>
    </div>
  </>
)}
    </>
  );
};

export default Header;
