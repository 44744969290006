import React, { useState } from "react";
import img from "../assets/images.jpg";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    let hardcodedCred = {
      username: "admin",
      password: "admin@1234",
    };

    if (
      username === hardcodedCred.username &&
      password === hardcodedCred.password
    ) {
      const token = "123456abcdef";
      sessionStorage.setItem("auth-token", token);

      navigate("/");
    } else {
      swal("wrong email or password combination");
    }
  };

  //   localStorage.setItem("users", JSON.stringify(username, password));

  //   navigate("/");
  // };

  return (
    <>
      <div className="flex  items-center  justify-center py-12 px-4 sm:px-4 lg:px-5 py ">
        <div className="w-full bg-white mt-20 max-w-md  shadow-gray-600  shadow-2xl rounded-md  ">
          <div className=" mt-2 flex justify-center ">
            <img src={img} className="App-logo w-28 h-20" alt="logo" />
          </div>
          <div>
            <h2 className="mt-2 text-center text-xl font-bold tracking-tight text-blue-900">
              Login in to your account
            </h2>
          </div>

          <div class="flex justify-center items-center rounded-md  ">
            <form onSubmit={handleLogin} className=" space-y-6 w-80">
              <input type="hidden" name="remember" defaultValue="true" />
              <div className=" -space-y-px rounded-lg ">
                {/* <div> */}
                <label htmlFor="username" className="text-sm">
                  User Name
                </label>
                <input
                  name="username"
                  autoComplete="off"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  autocomplete="off"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  placeholder="username"
                />

                <br />

                <label htmlFor="password" className="text-sm">
                  Password
                </label>

                <input
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autocomplete="off"
                  name="password"
                  type="password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  placeholder="Password"
                />

                {/* </div> */}
              </div>
              <div className="text-sm">
                <a
                  href="#"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Forgot your password?
                </a>
              </div>
              <div>
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mb-2"
                >
                  <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg
                      class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
