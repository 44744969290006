

import React, { useState, useEffect } from 'react';
import Header from './layout/Header';
import { useNavigate } from 'react-router-dom';
import config from './config';

const Ewt = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [ewtData, setEwtData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    fetch(`${config.apiUrl}/getewt`,  {
      method: 'POST',
      headers: {
        'Authorization': config.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setEwtData(data.data);
        } else {
          setError('No records present');
        }
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching EWT data');
        setLoading(false);
      });
  }, []);

  const handleDeleteClick = (lobName) => {
    fetch(`${config.apiUrl}/deleteewt`, {
      method: 'POST',
      headers: {
        'Authorization': config.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ lob: lobName }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          const updatedData = ewtData.filter((item) => item.lob !== lobName);
          setEwtData(updatedData);
          setSuccessMessage(data.message);
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError('Error deleting EWT data');
      });
  };

  const tableStyles = {
    marginLeft: '20%',
  };

  return (
    <>
      <Header />
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 lg:px-6">
            <div className="overflow-hidden">
              {ewtData.length === 0 ? (
                <p className="text-center">No data available</p>
              ) : (
                <table
                  className="max-w-96 text-center text-sm font-light border border-collapse border-neutral-300 dark:border-neutral-500"
                  style={tableStyles}
                >
                  <thead className="border-b bg-neutral-300 font-medium text-black dark:border-neutral-500 dark:bg-neutral-900">
                    <tr>
                      <th scope="col" className="px-6 py-4 border-r">
                        Sno
                      </th>
                      <th scope="col" className="px-6 py-4 border-r">
                        LOB Name
                      </th>
                      <th scope="col" className="px-6 py-4 border-r">
                        Queues
                      </th>
                      <th scope="col" className="px-6 py-4 border-r">
                        Edit
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ewtData.map((item, index) => (
                      <tr key={index} className="border-b">
                        <td className="px-4 py-3 sm:px-10 border-r">
                          {index + 1}
                        </td>
                        <td className="px-4 py-3 sm:px-10 border-r">
                          {item.lob}
                        </td>
                        <td className="px-4 py-3 sm:px-10 border-r">
                          {Array.isArray(item.queues) ? (
                            item.queues.map((queue, queueIndex) => (
                              <div key={queueIndex}>{queue.queuename}</div>
                            ))
                          ) : (
                            <div>No queues available</div>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 border-r">
                          <a
                            href={`/EditEWT/${item._id}?lobName=${encodeURIComponent(
                              item.lob
                            )}&${item.queues
                              .map(
                                (queue) =>
                                  `queueid=${encodeURIComponent(
                                    queue.queueid
                                  )}&queueNames=${encodeURIComponent(
                                    queue.queuename
                                  )}`
                              )
                              .join('&')}`}
                            className="edit-button inline-block"
                            style={{
                              backgroundColor: 'purple',
                              color: 'white',
                              fontSize: '18px',
                              padding: '10px 20px',
                            }}
                          >
                            Edit
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          <a
                            onClick={() => handleDeleteClick(item.lob)}
                            className="delete-button inline-block"
                            style={{
                              backgroundColor: 'purple',
                              color: 'white',
                              fontSize: '18px',
                              padding: '10px 20px',
                              cursor: 'pointer',
                            }}
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        {successMessage && (
          <p className="text-green-500 text-center mt-4 ">
            {successMessage}
          </p>
        )}
      </div>
    </>
  );
};

export default Ewt;
