// import React, { useState, useEffect } from 'react';
// import Header from '../layout/Header';
// import { useLocation } from 'react-router-dom';
// import Select from 'react-select';
// import config from '../config';
 
// const CustomOption = ({ innerProps, label }) => (
//   <div {...innerProps}>{label}</div>
// );
 
// export default function EditEWT() {
//   const location = useLocation();
//   const [lobName, setLobName] = useState(location.state ? location.state.lobName : '');
//   const [listOfQueues, setListOfQueues] = useState([]);
//   const [queueNames, setQueueNames] = useState([]);
//   const [dropdownComponents, setDropdownComponents] = useState([0]);
//   const [percentages, setPercentages] = useState([]); // State for percentages
//   const [successMessage, setSuccessMessage] = useState(''); // State for success message
//   const [lobNameError, setLobNameError] = useState(''); // State for lob name validation error
//   const [percentageError, setPercentageError] = useState('');
 
//   const customDropdownStyles = {
//     control: (provided) => ({
//       ...provided,
//       width: 300,
//        // Adjust the width as needed
//     }),
//   };
 
//   useEffect(() => {
//     // Fetch queue names on component mount
//     fetchQueueNames();
//   }, []);
 
//   const fetchQueueNames = () => {
//     fetch(`${config.apiUrl}/getqueues`, {
//       method: 'POST',
//       headers: {
//         'Authorization': config.token,
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         const options = data.map((queue) => ({
//           value: queue.id,
//           label: queue.name,
//         }));
//         setQueueNames(options);
//       })
//       .catch((error) => {
//         console.error('Error fetching queue names:', error);
//       });
//   };
 
//   const handleInputChange = (event) => {
//     const value = event.target.value;
//     // Validate the input to contain only characters
//     if (/^[a-zA-Z ]*$/.test(value)) {
//       setLobName(value);
//       setLobNameError(''); // Clear validation error
//     } else {
//       setLobNameError('LOB Name should only contain characters.');
//     }
//   };
 
//   const handleQueueChange = (options, dropdownIndex) => {
//     // Update the selected queues for the specified dropdown
//     const updatedListOfQueues = [...listOfQueues];
//     updatedListOfQueues[dropdownIndex] = options;
//     setListOfQueues(updatedListOfQueues);
//   };
 
//   const handlePercentageChange = (value, dropdownIndex) => {
//     const updatedPercentages = [...percentages];
//     updatedPercentages[dropdownIndex] = value;
//     setPercentages(updatedPercentages);
//   };
 
//   const handleAddQueueClick = () => {
//     setDropdownComponents([...dropdownComponents, dropdownComponents.length]);
//     // Add an empty array for the newly added dropdown
//     setListOfQueues([...listOfQueues, []]);
//     // Add an empty percentage for the newly added dropdown
//     setPercentages([...percentages, 0]);
//   };
 
//   const handleEditClick = () => {
//     // Check if lobName is empty or contains only spaces
//     if (lobName.trim() === '') {
//       setLobNameError('LOB Name is required');
//       return;
//     }
  
//     // Check if any queues are selected
//     if (listOfQueues.every(queues => queues.length === 0)) {
//       setSuccessMessage(''); // Clear any previous success message
//       setPercentageError('');
//       setLobNameError(''); // Clear validation error
//       setSuccessMessage(
//         <span style={{ color: 'red' }}>Queue not selected.</span>
//       );
//       return;
//     }
  
//     const totalPercentage = percentages.reduce((acc, value) => acc + Number(value), 0);
  
//     if (totalPercentage !== 100) {
//       setSuccessMessage(''); // Clear any previous success message
//       setPercentageError('Total percentage should be 100');
//       return;
//     } else {
//       setPercentageError(''); // Clear the percentage error if total is 100
//     }
  
//     // Your code to handle the edit action, including the percentages
//     const requestData = {
//       lob: lobName,
//       queues: listOfQueues.map((queues, index) => ({
//         queuename: queues[0].label,
//         queueid: queues[0].value,
//         percentage: percentages[index],
//       })),
//     };
  
//     // Submit the requestData to your server endpoint
//     fetch(`${config.apiUrl}/addpercentage`, {
//       method: 'POST',
//       headers: {
//         'Authorization': config.token,
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(requestData),
//     })
//       .then((response) => response.json())
//       .then((insertData) => {
//         // Handle the response from the API
//         setSuccessMessage('Data saved successfully');
//         console.log(insertData);
//       })
//       .catch((error) => {
//         console.error('Error inserting EWT configuration:', error);
//       });
//   };
  
//   return (
//     <div>
//       <Header />
//       <div className="flex flex-col items-center">
//         <br />
//         <div className="flex items-center">
//         <label htmlFor="lobName" className="text-center mr-2 font-roboto">
//             LOB Name<span style={{ color: "red" }}> * </span>
//           </label>
//           <input
//             type="text"
//             id="lobName"
//             className="border border-black-200 px-1 py-1 w-50 text-center"
//             placeholder="Enter LOB Name"
//             value={lobName}
//             onChange={handleInputChange}
//             style={{ marginLeft: '10px' }}
//           />
//         </div>
//         {lobNameError && <p className="text-red-500">{lobNameError}</p>}
//         <br />
//         <br />
//         {/* <h2 className="text-l font-semibold mb-2 text-left">Queues</h2 */}
//         <br />
//         <div>
//           {dropdownComponents.map((componentIndex) => (
//             <div key={componentIndex} style={{ marginBottom: '10px',cursor: "pointer", display: 'flex', alignItems: 'center' }}>
//               <div style={{ marginRight: '10px' }}>
//                 <h2 className="text-l font-semibold mb-2">Queue Name <span style={{ color: "red" }}> * </span></h2>
//                 <Select
//                   options={queueNames}
//                   value={listOfQueues[componentIndex] || []}
//                   isMulti
//                   onChange={(options) => handleQueueChange(options, componentIndex)}
//                   components={{ Option: CustomOption }}
//                   styles={customDropdownStyles}
//                 />
//               </div>
//               <div>
//               <h2 className="text-l font-semibold mb-2">Percentage<span style={{ color: "red" }}> * </span></h2>

//                 {/* <input
//                   type="number"
//                   min="0"
//                   max="100"
//                   value={percentages[componentIndex] || 0}
//                   onChange={(e) => handlePercentageChange(e.target.value, componentIndex)}
//                   className="border border-black-200 px-1 py-0 w-50 text-center"
//                   placeholder="Enter Percentage"
//                 />  % */}

//            <input
//                   type="number"
//                   min="0"
//                   max="100"
//                   value={percentages[componentIndex]}
//                   onChange={(e) => handlePercentageChange(e.target.value, componentIndex)}
//                   className="border border-black-200 px-1 py-0 w-50 text-center"
//                   placeholder="Enter Percentage"
//                 />  %
//               </div>
//             </div>
//           ))}
//         </div>
//         {percentageError && <p className="text-red-500">{percentageError}</p>}
//         <br />
//         <br />
//         <div>
//           <button
//             onClick={handleAddQueueClick}
//             className="bg-purple-900 text-white rounded-md px-4 py-1 mt-2"
//           >
//             Add New Queue
//           </button>
//           <button
//             onClick={handleEditClick}
//             className="bg-purple-900 text-white rounded-md px-4 py-1 ml-2"
//           >
//             Submit
//           </button>
//         </div>
//         {successMessage && <p className="text-green-500">{successMessage}</p>}
//       </div>
//     </div>
//   );
// }
 


import React, { useState, useEffect } from 'react';
import Header from '../layout/Header';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import config from '../config';

const CustomOption = ({ innerProps, label }) => (
  <div {...innerProps}>{label}</div>
);

export default function EditEWT() {
  const location = useLocation();
  const [lobName, setLobName] = useState(location.state ? location.state.lobName : '');
  const [listOfQueues, setListOfQueues] = useState([]);
  const [queueNames, setQueueNames] = useState([]);
  const [dropdownComponents, setDropdownComponents] = useState([0]);
  const [percentages, setPercentages] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [lobNameError, setLobNameError] = useState('');
  const [percentageError, setPercentageError] = useState('');

  const customDropdownStyles = {
    control: (provided) => ({
      ...provided,
      width: 300,
    }),
  };

  useEffect(() => {
    fetchQueueNames();
  }, []);

  const fetchQueueNames = () => {
    fetch(`${config.apiUrl}/getqueues`, {
      method: 'POST',
      headers: {
        'Authorization': config.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((queue) => ({
          value: queue.id,
          label: queue.name,
        }));
        setQueueNames(options);
      })
      .catch((error) => {
        console.error('Error fetching queue names:', error);
      });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (/^[a-zA-Z ]*$/.test(value)) {
      setLobName(value);
      setLobNameError('');
    } else {
      setLobNameError('LOB Name should only contain characters.');
    }
  };

  const handleQueueChange = (options, dropdownIndex) => {
    const updatedListOfQueues = [...listOfQueues];
    updatedListOfQueues[dropdownIndex] = options;
    setListOfQueues(updatedListOfQueues);
  };

  const handlePercentageChange = (value, dropdownIndex) => {
    const updatedPercentages = [...percentages];
    updatedPercentages[dropdownIndex] = value;
    setPercentages(updatedPercentages);
  };

  const handleAddQueueClick = () => {
    setDropdownComponents([...dropdownComponents, dropdownComponents.length]);
    setListOfQueues([...listOfQueues, []]);
    setPercentages([...percentages, 0]);
  };

  const handleRemoveQueueClick = (index) => {
    const updatedListOfQueues = [...listOfQueues];
    updatedListOfQueues.splice(index, 1);

    const updatedPercentages = [...percentages];
    updatedPercentages.splice(index, 1);

    const updatedDropdownComponents = [...dropdownComponents];
    updatedDropdownComponents.splice(index, 1);

    setListOfQueues(updatedListOfQueues);
    setPercentages(updatedPercentages);
    setDropdownComponents(updatedDropdownComponents);
  };

  const handleEditClick = () => {
    if (lobName.trim() === '') {
      setLobNameError('LOB Name is required');
      return;
    }

    if (listOfQueues.every((queues) => queues.length === 0)) {
      setSuccessMessage('');
      setPercentageError('');
      setLobNameError('');
      setSuccessMessage(
        <span style={{ color: 'red' }}>Queue not selected.</span>
      );
      return;
    }

    const totalPercentage = percentages.reduce((acc, value) => acc + Number(value), 0);

    if (totalPercentage !== 100) {
      setSuccessMessage('');
      setPercentageError('Total percentage should be 100');
      return;
    } else {
      setPercentageError('');
    }

    const requestData = {
      lob: lobName,
      queues: listOfQueues.map((queues, index) => ({
        queuename: queues[0].label,
        queueid: queues[0].value,
        percentage: percentages[index],
      })),
    };

    fetch(`${config.apiUrl}/addpercentage`, {
      method: 'POST',
      headers: {
        'Authorization': config.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((insertData) => {
        setSuccessMessage('Data saved successfully');
        console.log(insertData);
      })
      .catch((error) => {
        console.error('Error inserting EWT configuration:', error);
      });
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center">
        <br />
        <div className="flex items-center">
          <label htmlFor="lobName" className="text-center mr-2 font-roboto">
            LOB Name<span style={{ color: "red" }}> * </span>
          </label>
          <input
            type="text"
            id="lobName"
            className="border border-black-200 px-1 py-1 w-50 text-center"
            placeholder="Enter LOB Name"
            value={lobName}
            onChange={handleInputChange}
            style={{ marginLeft: '10px' }}
          />
        </div>
        {lobNameError && <p className="text-red-500">{lobNameError}</p>}
        <br />
        <br />
        <br />
        <div>
          {dropdownComponents.map((componentIndex) => (
            <div key={componentIndex} style={{ marginBottom: '10px', cursor: "pointer", display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px' }}>
                <h2 className="text-l font-semibold mb-2">Queue Name <span style={{ color: "red" }}> * </span></h2>
                <Select
                  options={queueNames}
                  value={listOfQueues[componentIndex] || []}
                  isMulti
                  onChange={(options) => handleQueueChange(options, componentIndex)}
                  components={{ Option: CustomOption }}
                  styles={customDropdownStyles}
                />
              </div>
              <div>
                <h2 className="text-l font-semibold mb-2">Percentage<span style={{ color: "red" }}> * </span></h2>
                {/* <input
                  type="number"
                  min="0"
                  max="100"
                  value={percentages[componentIndex]}
                  onChange={(e) => handlePercentageChange(e.target.value, componentIndex)}
                  className="border border-black-200 px-1 py-0 w-50 text-center"
                  placeholder="Enter Percentage"
                />   % */}
                <input
  type="number"
  min="0"
  max="100"
  value={percentages[componentIndex]}
  onChange={(e) => handlePercentageChange(e.target.value, componentIndex)}
  className="border border-black-200 px-1 py-0 w-50 text-center enlarged-input"
  placeholder="Enter Percentage"
/>%

              </div>
              <div>
                <button
                  onClick={() => handleRemoveQueueClick(componentIndex)}
                  className="bg-purple-900 text-white rounded-md mt-8 px-2 py-1 ml-2"
                >
                  Remove Queue
                </button>
              </div>
            </div>
          ))}
        </div>
        {percentageError && <p className="text-red-500">{percentageError}</p>}
        <br />
        <br />
        <div>
          <button
            onClick={handleAddQueueClick}
            className="bg-purple-900 text-white rounded-md px-4 py-1 mt-2"
          >
            Add New Queue
          </button>
          <button
            onClick={handleEditClick}
            className="bg-purple-900 text-white rounded-md px-4 py-1 ml-2"
          >
            Submit
          </button>
        </div>
        {successMessage && <p className="text-green-500">{successMessage}</p>}
      </div>
    </div>
  );
}
