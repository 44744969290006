import React, { useState, useEffect } from 'react';
import Header from '../layout/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../config';
 
export default function EditEWT() {
  const location = useLocation();
  const navigate = useNavigate();
  const [lobName, setLobName] = useState(location.state ? location.state.lobName : '');
  const [listOfQueues, setListOfQueues] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedListOption, setSelectedListOption] = useState(null);
 
  useEffect(() => {
    // Fetch queue names from your API
    fetch(`${config.apiUrl}/getqueues`, {
      method: 'POST',
      headers: {
        'Authorization': config.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((queue) => ({
          value: queue.id,
          label: queue.name,
        }));
        setListOfQueues(options); // Initialize List of Queues
      })
      .catch((error) => {
        console.error('Error fetching queue names:', error);
      });
  }, []);
 
  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const lobNameParam = searchParams.get('lobName');
  //   if (lobNameParam) {
  //     setLobName(lobNameParam);
  //   }
 
  //   const queueNamesParam = searchParams.getAll('queueNames');
  //   const queueidParams = searchParams.getAll('queueid');
 
  //   if (queueNamesParam.length > 0 && queueNamesParam.length === queueidParams.length) {
  //     const selectedQueues = queueNamesParam.map((queueName, index) => ({
  //       label: queueName,
  //       value: queueidParams[index],
  //     }));
 
  //     setSelectedQueues(selectedQueues);
  //   }
  // }, [location.search]);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
 
    const lobNameParam = searchParams.get('lobName');
    if (lobNameParam) {
      const decodedLobName = decodeURIComponent(lobNameParam);
      console.log('Decoded lobName:', decodedLobName);
      setLobName(decodedLobName);
    }
 
    const queueNamesParam = searchParams.getAll('queueNames');
    const queueidParams = searchParams.getAll('queueid');
 
    if (queueNamesParam.length > 0 && queueNamesParam.length === queueidParams.length) {
      const selectedQueues = queueNamesParam.map((queueName, index) => ({
        label: decodeURIComponent(queueName),
        value: queueidParams[index],
      }));
 
      console.log('Decoded queueNames:', selectedQueues);
 
      setSelectedQueues(selectedQueues);
    }
  }, [location.search]);
 
  const handleInputChange = (event) => {
    setLobName(event.target.value);
  };
 
  const handleListOptionClick = (queue) => {
    setSelectedListOption(queue);
  };
 
  const moveSelectedToRight = () => {
    if (selectedListOption) {
      setSelectedQueues((prevSelectedQueues) => [...prevSelectedQueues, selectedListOption]);
      setListOfQueues((prevList) => prevList.filter((queue) => queue.value !== selectedListOption.value));
      setSelectedListOption(null);
    }
  };
 
  const moveSelectedToLeft = () => {
    if (selectedListOption) {
      setListOfQueues((prevList) => [...prevList, selectedListOption]);
      setSelectedQueues((prevSelectedQueues) =>
        prevSelectedQueues.filter((queue) => queue.value !== selectedListOption.value)
      );
      setSelectedListOption(null);
    }
  };
 
  const handleEditClick = () => {
    const requestData = {
      lob: lobName,
      queues: selectedQueues.map((queue) => ({
        queuename: queue.label,
        queueid: queue.value,
      })),
    };
 
    // Send an HTTP request to update the configuration
    fetch(`${config.apiUrl}/editewt`, {
      method: 'POST',
      headers: {
        'Authorization': config.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((updateData) => {
        // Update the selected queues with both existing and new values
        setSelectedQueues((prevSelectedQueues) => [
          ...prevSelectedQueues,
          ...requestData.queues,
        ]);
 
        // Set the success message when the update is successful
        setSuccessMessage('Data updated successfully');
        setTimeout(() => {
          // Use your navigation logic here, for example:
          navigate('/EWT');
        }, 1000);
      })
      .catch((error) => {
        console.error('Error updating EWT configuration:', error);
        // Set an error message when the update fails
        setSuccessMessage('Failed to update data');
      });
  };
 
  const handleDeselectQueue = (deselectedQueue) => {
    setSelectedQueues((prevSelectedQueues) =>
      prevSelectedQueues.filter((queue) => queue.label !== deselectedQueue.label)
    );
 
    setListOfQueues((prevList) => [...prevList, deselectedQueue]);
  };
 
  return (
    <div className="bg-gray-100 min-h-screen">
      <Header />
 
      <div className="p-4 max-w-screen-xl mx-auto">
        <div className="flex flex-col items-center">
        <div className="flex items-center">
          <label htmlFor="lobName" className="text-center mr-2 font-roboto">
            LOB Name:   <span style={{ color: "red" }}> * </span>
          </label>
          <input
            type="text"
            id="lobName"
            className="border border-black-200 px-1 py-0 w-50 text-center"
            placeholder="Enter LOB Name"
            value={lobName}
            onChange={handleInputChange}
            style={{ marginLeft: '50px' }}
          />
        </div>
 
         
        <div className="mb-8 md:mt-16">
          <h2 className="text-lg font-semibold mb-2 text-center">Queues </h2>
 
          <div className="md:flex space-x-10 space-y-0">
            {/* List of Queues div on the left for larger screens */}
            <div className="w-full md:w-1/2 md:mt-0 ml-auto flex flex-col items-center">
              <h2 className="text-lg font-semibold mb-5 ml-0">List of Queues <span style={{ color: "red" }}> * </span></h2>
              <div
                style={{
                  overflowY: 'auto',
                  height: '200px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                }}
              >
                {listOfQueues.map((queue) => (
                  <div
                    key={queue.value}
                    style={{
                      padding: '10px',
                      cursor: 'pointer',
                      backgroundColor: selectedListOption && selectedListOption.value === queue.value ? '#purple' : 'white',
                      color: selectedListOption && selectedListOption.value === queue.value ? 'purple' : 'black',
                    }}
                    onClick={() => handleListOptionClick(queue)}
                  >
                    {queue.label}
                  </div>
                ))}
              </div>
            </div>
            {/* End of List of Queues div for larger screens */}
           
            <div className="flex flex-col items-center md:flex-row">
              {/* Move the arrow and the selected queues listbox to the right */}
              <div className="md:ml-1 flex flex-col items-center">
                <button
                  onClick={moveSelectedToRight}
                  className="mt-10 px-3 py-0 bg-purple-800 text-white rounded-lg"
                  style={{ marginLeft: '10px' }} // Adjust the left margin here
                >
                  &rarr;
                </button>
              </div>
              </div>
              <div className="w-full md:w-1/2 md:mt-1 ml-auto flex flex-col items-center">
                <h2 className="text-lg font-semibold mb-5 ml-0">Selected Queues  <span style={{ color: "red" }}> * </span></h2>
                <div
                  style={{
                    overflowY: 'auto',
                    height: '200px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                  }}
                >
                  {selectedQueues.map((queue) => (
                    <div
                      key={queue.value}
                      style={{
                        padding: '10px',
                        cursor: 'pointer',
                        backgroundColor: selectedListOption && selectedListOption.value === queue.value ? '#purple' : 'white',
                        color: selectedListOption && selectedListOption.value === queue.value ? 'purple' : 'black',
                      }}
                      onClick={() => handleDeselectQueue(queue)}
                    >
                      {queue.label}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
 
          <div className="flex flex-col items-center">
            <div>
              {successMessage && (
                <p className="text-green-600 text-lg font-semibold">
                  {successMessage}
                </p>
              )}
            </div>
            <div>
              <button
                onClick={handleEditClick}
                className="bg-purple-900 text-white rounded-md px-4 py-2 mt-6"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}