

import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import axios from "axios";
import config from "../config";

export default function Report() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    fetch(`${config.apiUrl}/getpercentage`,  {
            method: "POST",
      headers: {
        'Authorization': config.token,
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.status === 200) {
          setData(responseData.data);
        } else {
          setError("No records present");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching percentage data");
        setLoading(false);
      });
  }, []);

  const handleDelete = (lob) => {
    axios
      .post(
        `${config.apiUrl}/deletepercentage`,
        { lob },
        {
          headers: {
            'Authorization': config.token,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          const updatedData = data.filter((item) => item.lob !== lob);
          setData(updatedData);
          setSuccessMessage("Data deleted successfully");
        } else {
          setError("Error deleting data");
        }
      })
      .catch((error) => {
        setError("Error deleting data");
      });
  };

  const tableStyles = {
    marginLeft: "20%",
  };

  return (
    <>
      <Header />
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 lg:px-6">
            <div className="overflow-hidden">
              {data.length === 0 ? (
                <p className="text-center">No data available</p>
              ) : (
                <table
                  className="max-w-96 text-center text-sm font-light border border-collapse border-neutral-300 dark:border-neutral-500"
                  style={tableStyles}
                >
                  <thead className="border-b bg-neutral-300 font-medium text-black dark:border-neutral-500 dark:bg-neutral-900">
                    <tr>
                      <th scope="col" className="px-6 py-4 border-r">
                        Sno
                      </th>
                      <th scope="col" className="px-6 py-4 border-r">
                        LOB Name
                      </th>
                      <th scope="col" className="px-6 py-4 border-r">
                        Queues
                      </th>
                      <th scope="col" className="px-6 py-4 border-r">
                        Edit
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={item._id} className="border-b dark:border-neutral-500">
                        <td className="whitespace-nowrap px-6 py-4 font-medium border-r">
                          {index + 1}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 border-r">
                          {item.lob}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 border-r">
                          {item.queues.map((queue, index) => (
                            <span key={queue.queuename}>
                              {queue.queuename}
                              {index < item.queues.length - 1 && <br />}
                            </span>
                          ))}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 border-r">
                          <a
                            href={`editpercentage/${item._id}?lobName=${encodeURIComponent(item.lob)}&queueNames=${encodeURIComponent(item.queues.map((queue) => queue.queuename).join(","))}&queueids=${encodeURIComponent(item.queues.map((queue) => queue.queueid).join(","))}&percentages=${encodeURIComponent(item.queues.map((queue) => queue.percentage).join(","))}`}
                            className="edit-button inline-block"
                            style={{
                              backgroundColor: "purple",
                              color: "white",
                              fontSize: "18px",
                              padding: "10px 20px",
                            }}
                          >
                            Edit
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          <a
                            onClick={() => handleDelete(item.lob)}
                            className="delete-button inline-block"
                            style={{
                              backgroundColor: "purple",
                              color: "white",
                              fontSize: "18px",
                              padding: "10px 20px",
                              cursor: "pointer",
                            }}
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        {successMessage && (
          <p className="text-green-500 text-center mt-4 ">{successMessage}</p>
        )}
      </div>
    </>
  );
}
