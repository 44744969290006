import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./component/Login";
import EWT from "./component/EWT";
import ProtectedRoutes from "./Services/ProtectedRoutes";
import Header from "./component/layout/Header";
import Percentage from "./component/pages/Percentage";
import PercentageEdit from "./component/pages/PercentageEdit";
import EditEWT  from "./component/pages/EditEWT";
import AddPercentage from "./component/pages/AddPercentage";
import AddEWT  from "./component/pages/AddEWT";
 

import config from "./component/config";
function App() {
  return (
    <>
      <div className="">
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            {/* <Route path="/register" element={<Register />} /> */}

            {/* protected routes */}
            <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/" element={<Header />} />
              {/* <Route path="/ewt" element={<EWT />} /> */}
              <Route path="/EWT" element={<EWT />} />
              <Route path="/percentage" element={<Percentage />} />
              <Route path="/editpercentage/:id" element={<PercentageEdit />} />
              
              <Route path="/addpercentage" element={<AddPercentage />} />
              {/* <Route path="/EditEWT" element={<EditEWT />} /> */}
              <Route path="/config" element={<config />} />
              <Route path="/AddEWT" element={<AddEWT />} />
              
              <Route path="/EditEWT/:id" element={<EditEWT />} />

 

              

            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
